import React from 'react';
import AddressData from '../../../../assets/data/AddressData';

function UsersAddressPage() {
    return (
        <div className="col-12 col-lg-9 col-xl-9 col-md-9">
            <div className="user-section-title">Address Book</div>

            <div className="user-section-order">
                <table>
                    <thead style={{ textAlign: 'center' }}>
                        <th style={{ textAlign: 'left' }}>Name</th>
                        <th>Phone Number</th>
                        <th>Address</th>
                        <th>Action</th>
                    </thead>
                    <tbody>
                        {AddressData?.slice(0, 10).map((row) => (
                            <tr key={row?.id}>
                                <td>{row?.title}</td>
                                <td>{row?.phone}</td>
                                <td>
                                    {row?.address?.flat},{row?.address?.road},
                                    {row?.address?.address},{row?.address?.city},
                                    {row?.address?.district}
                                </td>
                                <td>Edit</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button type="button" className="float-right">
                    Add New
                </button>
            </div>
        </div>
    );
}

export default UsersAddressPage;
