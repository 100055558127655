import Arrow from '../../../assets/icons/breadcamb-arrow.svg';

export default function Breadcrumb({ title }) {
    return (
        <div className="breadcrumb-section">
            <div className="container">
                <ul>
                    <li>
                        Home <img src={Arrow} alt="" />
                    </li>
                    <li>
                        {title} <img src={Arrow} alt="" />
                    </li>
                </ul>
            </div>
        </div>
    );
}
