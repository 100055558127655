import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import SendIcon from '../../../../assets/icons/Send-icon.svg';
import bestBanner from '../../../../assets/images/banner-4.png';
import Slider1 from '../../../../assets/images/slider-1.png';
import Slider2 from '../../../../assets/images/slider-2.png';

export default function SliderSectionTwo() {
    return (
        <div className="slider-section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-3 col-xl-3 col-md-6">
                        <div
                            className="best-sell-section-banner"
                            style={{ width: '100%', height: '350px' }}
                        >
                            <img src={bestBanner} alt="" />
                            <div className="best-sell-section-banner-info">
                                <h4>
                                    <span style={{ background: 'white' }}>
                                        Bring nature into your home
                                    </span>
                                </h4>
                                <Link to="/">Shop Now</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-9 col-xl-9 col-md-6">
                        <Carousel>
                            <Carousel.Item>
                                <img className="d-block w-100" src={Slider1} alt="First slide" />
                                <Carousel.Caption>
                                    <h3>
                                        Don’t miss amazing
                                        <br />
                                        Grocery Deals
                                    </h3>
                                    <p>
                                        Nulla vitae elit libero, a pharetra augue mollis interdum.
                                    </p>
                                    <div className="subscribe-input">
                                        <div className="input-group mb-3">
                                            <span className="input-group-icon">
                                                <img
                                                    src={SendIcon}
                                                    alt=""
                                                    style={{ width: 20, height: 'auto' }}
                                                />
                                            </span>
                                            <input
                                                type="text"
                                                aria-label=""
                                                placeholder="Your Email Address"
                                            />
                                            <span className="input-group-subscribe">Subscribe</span>
                                        </div>
                                    </div>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={Slider2} alt="Second slide" />

                                <Carousel.Caption>
                                    <h3>
                                        Fresh Vegetables
                                        <br />
                                        Big Discount
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    <button type="button">Discover</button>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    );
}
