import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

function QtyBtn() {
    const [newQuantity, setNewQuantity] = useState(1);

    const handleQtyPlus = () => {
        setNewQuantity(newQuantity + 1);
    };

    const handleQtyMinus = () => {
        if (newQuantity <= 1) {
            setNewQuantity(1);
        } else {
            setNewQuantity(newQuantity - 1);
        }
    };
    const handleChange = () => {};
    return (
        <>
            <button type="button" onClick={handleQtyMinus}>
                <FontAwesomeIcon size="sm" icon={faMinus} />
            </button>

            <input
                style={{ border: 'none', width: '50px', textAlign: 'center' }}
                onChange={handleChange}
                value={newQuantity}
                type="text"
            />
            <button type="button" onClick={handleQtyPlus}>
                <FontAwesomeIcon size="sm" icon={faPlus} />
            </button>
        </>
    );
}
export default QtyBtn;
