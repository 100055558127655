import React from 'react';
import ProductData from '../../../../assets/data/ProductData';
import ItemThree from '../../../components/section/ItemThree';

export default function DealsDaySection() {
    return (
        <div className="container">
            <div className="deals-day-section">
                <div className="deals-day-section-title">
                    <h4>Deals Of The Day</h4>
                </div>
                <div className="deals-day-section-list animate fadeInLeft point-five-delay">
                    {ProductData?.slice(0, 4).map((row) => (
                        <div className="deals-day-section-item" key={`deal-d-${row?.id}`}>
                            <ItemThree data={row} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
