import React from 'react';
import Breadcrumb from '../../../components/section/Breadcrumb';
import CartSection from '../section/CartSection';
import FooterSection from '../section/FooterSection';
import HeaderSection from '../section/HeaderSection';
import OptionSection from '../section/OptionSection';

export default function CartPage() {
    return (
        <>
            <HeaderSection />
            <Breadcrumb title="Cart" />
            <CartSection />
            <OptionSection />
            <FooterSection />
        </>
    );
}
