/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

function UsersProfilePage() {
    return (
        <div className="col-12 col-lg-9 col-xl-9 col-md-9">
            <div className="user-section-title">Profile</div>

            <div className="row g-4">
                <div className="col-12">
                    <div className="user-section-profile">
                        <label htmlFor="name">Name</label>
                        <input type="text" placeholder="Type Name" id="name" />
                        <button type="button">Change</button>
                    </div>
                </div>
                <div className="col-12">
                    <div className="user-section-profile">
                        <label htmlFor="email">Email Address</label>

                        <input type="text" placeholder="Type Email Address" id="email" />
                        <button type="button">Change</button>
                    </div>
                </div>
                <div className="col-12">
                    <div className="user-section-profile">
                        <label htmlFor="contact">Contact Number</label>

                        <input type="text" placeholder="Type Phone Number" id="contact" />
                        <button type="button">Change</button>
                    </div>
                </div>
                <div className="col-12">
                    <div className="user-section-profile">
                        <label htmlFor="pass">Password</label>

                        <input type="password" placeholder="******" id="pass" />
                        <button type="button">Change</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UsersProfilePage;
