import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import StarFill from '../../../../assets/icons/star-fill.svg';
import StarNotFill from '../../../../assets/icons/star-not-fill.svg';
import CartIcon from '../../../../assets/images/Cart.svg';
import Pro15 from '../../../../assets/images/products/product-15-1.jpg';
import Pro151 from '../../../../assets/images/products/product-15-2.jpg';
import UserImg from '../../../../assets/images/userImg.png';
import QtyBtn from '../../../components/section/QtyBtn';
import ImageSliderComponent from './ImageSliderComponent';

export default function ProductDetailSection() {
    const images = [{ id: Pro15 }, { id: Pro151 }];
    const [isWish] = useState(false);

    const fillStar = <img src={StarFill} style={{ width: 10, marginRight: 3 }} alt="" />;
    const fillNotStar = <img src={StarNotFill} style={{ width: 10, marginRight: 3 }} alt="" />;
    return (
        <div className="col-12 col-lg-9 col-xl-9">
            <div className="row">
                <div className="col-12 col-lg-6 col-xl-6 col-md-6 mb-4">
                    <div className="product-detail-section-img">
                        <ImageSliderComponent thumbImages={images} />
                    </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-6 col-md-6 mb-4">
                    <div className="product-detail-section-info">
                        <button type="button">Sale Off</button>
                        <h2>Seeds of Change Organic Quinoa, Brown</h2>
                        <span>
                            {fillStar}
                            {fillStar}
                            {fillStar}
                            {fillStar}
                            {fillNotStar}(40 Reviews)
                        </span>
                        <div className="price">
                            <h1>$32.89</h1>
                        </div>
                        <p>
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam rem
                            officia, corrupti reiciendis minima nisi modi, quasi, odio minus dolore
                            impedit fuga eum eligendi.
                        </p>
                        <div className="product-detail-size">
                            <h6 className="product-detail-size-title">Size:</h6>
                            <button type="button" className="product-detail-sizeBtn">
                                50Kg
                            </button>
                        </div>
                        <div className="product-detail-btn-group">
                            <div className="product-qty-btn">
                                <QtyBtn />
                            </div>

                            <div className="product-cart-btn" data-tip data-for="varia">
                                <button type="button">
                                    <img src={CartIcon} alt="" /> Buy Now
                                </button>
                            </div>
                            <button
                                type="button"
                                className={isWish ? 'product-fav-btn-white' : 'product-fav-btn'}
                            />
                        </div>
                        <div className="other">
                            <table>
                                <tr>
                                    <td>Type</td>
                                    <td>Organic</td>
                                    <td>SUK</td>
                                    <td>FWM15VKT</td>
                                </tr>
                                <tr>
                                    <td>MFG</td>
                                    <td>23/09/2022</td>
                                    <td>Tags</td>
                                    <td>Organic,Red</td>
                                </tr>

                                <tr>
                                    <td>Other</td>
                                    <td>Organic</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="product-detail-property">
                        <Tabs
                            defaultActiveKey="description"
                            transition={false}
                            id="noanim-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="description" title="Description">
                                <div className="">
                                    <p>
                                        Uninhibited carnally hired played in whimpered dear gorilla
                                        koala depending and much yikes off far quetzal goodness and
                                        from for grimaced goodness unaccountably and meadowlark near
                                        unblushingly crucial scallop tightly neurotic hungrily some
                                        and dear furiously this apart.
                                    </p>
                                    <p>
                                        Spluttered narrowly yikes left moth in yikes bowed this that
                                        grizzly much hello on spoon-fed that alas rethought much
                                        decently richly and wow against the frequent fluidly at
                                        formidable acceptably flapped besides and much circa far
                                        over the bucolically hey precarious goldfinch mastodon
                                        goodness gnashed a jellyfish and one however because.
                                    </p>
                                    <ul className="product-more-infor mt-30">
                                        <li>
                                            <span>Type Of Packing</span> Bottle
                                        </li>
                                        <li>
                                            <span>Color</span> Green, Pink, Powder Blue, Purple
                                        </li>
                                        <li>
                                            <span>Quantity Per Case</span> 100ml
                                        </li>
                                        <li>
                                            <span>Ethyl Alcohol</span> 70%
                                        </li>
                                        <li>
                                            <span>Piece In One</span> Carton
                                        </li>
                                    </ul>
                                    <hr className="wp-block-separator is-style-dots" />
                                    <p>
                                        Laconic overheard dear woodchuck wow this outrageously taut
                                        beaver hey hello far meadowlark imitatively egregiously
                                        hugged that yikes minimally unanimous pouted flirtatiously
                                        as beaver beheld above forward energetic across this jeepers
                                        beneficently cockily less a the raucously that magic upheld
                                        far so the this where crud then below after jeez enchanting
                                        drunkenly more much wow callously irrespective limpet.
                                    </p>
                                    <h4 className="mt-30">Packaging &amp; Delivery</h4>
                                    <hr className="wp-block-separator is-style-wide" />
                                    <p>
                                        Less lion goodness that euphemistically robin expeditiously
                                        bluebird smugly scratched far while thus cackled sheepishly
                                        rigid after due one assenting regarding censorious while
                                        occasional or this more crane went more as this less much
                                        amid overhung anathematic because much held one exuberantly
                                        sheep goodness so where rat wry well concomitantly.
                                    </p>
                                    <p>
                                        Scallop or far crud plain remarkably far by thus far iguana
                                        lewd precociously and and less rattlesnake contrary caustic
                                        wow this near alas and next and pled the yikes articulate
                                        about as less cackled dalmatian in much less well jeering
                                        for the thanks blindly sentimental whimpered less across
                                        objectively fanciful grimaced wildly some wow and rose
                                        jeepers outgrew lugubrious luridly irrationally attractively
                                        dachshund.
                                    </p>
                                    <h4 className="mt-30">Suggested Use</h4>
                                    <ul className="product-more-infor mt-30">
                                        <li>Refrigeration not necessary.</li>
                                        <li>Stir before serving</li>
                                    </ul>
                                    <h4 className="mt-30">Other Ingredients</h4>
                                    <ul className="product-more-infor mt-30">
                                        <li>Organic raw pecans, organic raw cashews.</li>
                                        <li>
                                            This butter was produced using a LTG (Low Temperature
                                            Grinding) process
                                        </li>
                                        <li>
                                            Made in machinery that processes tree nuts but does not
                                            process peanuts, gluten, dairy or soy
                                        </li>
                                    </ul>
                                    <h4 className="mt-30">Warnings</h4>
                                    <ul className="product-more-infor mt-30">
                                        <li>
                                            Oil separation occurs naturally. May contain pieces of
                                            shell.
                                        </li>
                                    </ul>
                                </div>
                            </Tab>
                            <Tab eventKey="additional" title="Additional Info">
                                <table className="font-md">
                                    <tbody>
                                        <tr className="stand-up">
                                            <th>Stand Up</th>
                                            <td>
                                                <p>35″L x 24″W x 37-45″H(front to back wheel)</p>
                                            </td>
                                        </tr>
                                        <tr className="folded-wo-wheels">
                                            <th>Folded (w/o wheels)</th>
                                            <td>
                                                <p>32.5″L x 18.5″W x 16.5″H</p>
                                            </td>
                                        </tr>
                                        <tr className="folded-w-wheels">
                                            <th>Folded (w/ wheels)</th>
                                            <td>
                                                <p>32.5″L x 24″W x 18.5″H</p>
                                            </td>
                                        </tr>
                                        <tr className="door-pass-through">
                                            <th>Door Pass Through</th>
                                            <td>
                                                <p>24</p>
                                            </td>
                                        </tr>
                                        <tr className="frame">
                                            <th>Frame</th>
                                            <td>
                                                <p>Aluminum</p>
                                            </td>
                                        </tr>
                                        <tr className="weight-wo-wheels">
                                            <th>Weight (w/o wheels)</th>
                                            <td>
                                                <p>20 LBS</p>
                                            </td>
                                        </tr>
                                        <tr className="weight-capacity">
                                            <th>Weight Capacity</th>
                                            <td>
                                                <p>60 LBS</p>
                                            </td>
                                        </tr>
                                        <tr className="width">
                                            <th>Width</th>
                                            <td>
                                                <p>24″</p>
                                            </td>
                                        </tr>
                                        <tr className="handle-height-ground-to-handle">
                                            <th>Handle height (ground to handle)</th>
                                            <td>
                                                <p>37-45″</p>
                                            </td>
                                        </tr>
                                        <tr className="wheels">
                                            <th>Wheels</th>
                                            <td>
                                                <p>12″ air / wide track slick tread</p>
                                            </td>
                                        </tr>
                                        <tr className="seat-back-height">
                                            <th>Seat back height</th>
                                            <td>
                                                <p>21.5″</p>
                                            </td>
                                        </tr>
                                        <tr className="head-room-inside-canopy">
                                            <th>Head room (inside canopy)</th>
                                            <td>
                                                <p>25″</p>
                                            </td>
                                        </tr>
                                        <tr className="pa_color">
                                            <th>Color</th>
                                            <td>
                                                <p>Black, Blue, Red, White</p>
                                            </td>
                                        </tr>
                                        <tr className="pa_size">
                                            <th>Size</th>
                                            <td>
                                                <p>M, S</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Tab>
                            <Tab eventKey="vendor" title="Vendor Info">
                                <div className="vendor-logo d-flex mb-30">
                                    <img src="assets/imgs/vendor/vendor-18.svg" alt="" />
                                    <div className="vendor-name ml-15">
                                        <h6>
                                            <a href="vendor-details-2.html">Noodles Co.</a>
                                        </h6>
                                        <div className="product-rate-cover text-end">
                                            <div className="product-rate d-inline-block">
                                                <div
                                                    className="product-rating"
                                                    style={{ width: '90%' }}
                                                />
                                            </div>
                                            <span className="font-small ml-5 text-muted">
                                                {' '}
                                                (32 reviews)
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <ul className="contact-infor mb-50">
                                    <li>
                                        <img
                                            src="assets/imgs/theme/icons/icon-location.svg"
                                            alt=""
                                        />
                                        <strong>Address: </strong>{' '}
                                        <span>
                                            5171 W Campbell Ave undefined Kent, Utah 53127 United
                                            States
                                        </span>
                                    </li>
                                    <li>
                                        <img
                                            src="assets/imgs/theme/icons/icon-contact.svg"
                                            alt=""
                                        />
                                        <strong>Contact Seller:</strong>
                                        <span>(+91) - 540-025-553</span>
                                    </li>
                                </ul>
                                <ul className="contact-infor mb-50">
                                    <li>
                                        <img
                                            src="assets/imgs/theme/icons/icon-location.svg"
                                            alt=""
                                        />
                                        <strong>Address: </strong>{' '}
                                        <span>
                                            5171 W Campbell Ave undefined Kent, Utah 53127 United
                                            States
                                        </span>
                                    </li>
                                    <li>
                                        <img
                                            src="assets/imgs/theme/icons/icon-contact.svg"
                                            alt=""
                                        />
                                        <strong>Contact Seller:</strong>
                                        <span>(+91) - 540-025-553</span>
                                    </li>
                                </ul>
                                <p>
                                    Noodles &amp; Company is an American fast-casual restaurant that
                                    offers international and American noodle dishes and pasta in
                                    addition to soups and salads. Noodles &amp; Company was founded
                                    in 1995 by Aaron Kennedy and is headquartered in Broomfield,
                                    Colorado. The company went public in 2013 and recorded a $457
                                    million revenue in 2017.In late 2018, there were 460 Noodles
                                    &amp; Company locations across 29 states and Washington, D.C.
                                </p>
                            </Tab>
                            <Tab eventKey="reviews" title="Reviews">
                                <div className="detail-comment-section">
                                    <div className="row">
                                        <div className="col-8">
                                            <h6 className="mb-3">
                                                Customer questions &amp; answers
                                            </h6>
                                            <div className="comment-list">
                                                <div className="single-comment">
                                                    <div className="single-comment-img">
                                                        <img src={UserImg} alt="" />
                                                        <p>Alex</p>
                                                    </div>

                                                    <div className="single-comment-des">
                                                        <span>
                                                            December 4, 2022 at 3:12 pm{' '}
                                                            <small>
                                                                {fillStar}
                                                                {fillStar}
                                                                {fillStar}
                                                                {fillStar}
                                                                {fillNotStar}
                                                            </small>
                                                        </span>
                                                        <p>
                                                            Lorem ipsum dolor sit amet, consectetur
                                                            adipisicing elit. Delectus, suscipit
                                                            exercitationem accusantium obcaecati
                                                            quos voluptate nesciunt facilis itaque
                                                            modi commodi dignissimos sequi
                                                            repudiandae minus ab deleniti totam
                                                            officia id incidunt?
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <h6 className="mb-3">Customer reviews</h6>
                                            <span>
                                                <small>
                                                    {fillStar}
                                                    {fillStar}
                                                    {fillStar}
                                                    {fillStar}
                                                    {fillNotStar}
                                                    (4 Out of 5)
                                                </small>
                                            </span>
                                            <div className="review-list">
                                                <div className="single-review-list">
                                                    <p>5 star (70%)</p>
                                                    <div className="single-review-bar">
                                                        <div
                                                            className="single-review-bar-fill"
                                                            style={{ width: '70%' }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="single-review-list">
                                                    <p>4 star (60%)</p>
                                                    <div className="single-review-bar">
                                                        <div
                                                            className="single-review-bar-fill"
                                                            style={{ width: '60%' }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="single-review-list">
                                                    <p>3 star (90%)</p>
                                                    <div className="single-review-bar">
                                                        <div
                                                            className="single-review-bar-fill"
                                                            style={{ width: '90%' }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="single-review-list">
                                                    <p>2 star (40%)</p>
                                                    <div className="single-review-bar">
                                                        <div
                                                            className="single-review-bar-fill"
                                                            style={{ width: '40%' }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="single-review-list">
                                                    <p>5 star (15%)</p>
                                                    <div className="single-review-bar">
                                                        <div
                                                            className="single-review-bar-fill"
                                                            style={{ width: '15%' }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
}
