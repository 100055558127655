import Icon1 from '../icons/icon-1.svg';
import Icon2 from '../icons/icon-2.svg';
import Icon3 from '../icons/icon-3.svg';
import Icon4 from '../icons/icon-4.svg';

const OptionData = [
    {
        id: 100001,
        title: 'Free Delivery',
        subTitle: '24/7 Services',
        icon: Icon1,
    },
    {
        id: 100002,
        title: 'Easy Returns',
        subTitle: 'Within 7 Days',
        icon: Icon2,
    },
    {
        id: 100003,
        title: 'Best Prices & Offers',
        subTitle: 'All Orders',
        icon: Icon3,
    },
    {
        id: 100004,
        title: 'Great Deal',
        subTitle: 'After Signup',
        icon: Icon3,
    },
    {
        id: 100005,
        title: 'Wide Collection',
        subTitle: 'All Products',
        icon: Icon4,
    },
];
export default OptionData;
