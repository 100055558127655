import React from 'react';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
// import BrowserInfo from '../../../utils/BrowserInfo';
import FooterSection from '../../system/section/FooterSection';
import HeaderSection from '../../system/section/HeaderSection';
import UserSidebarMenuSection from '../section/UserSidebarMenuSection';

function UserAuthHandler() {
    return (
        <>
            <HeaderSection />

            <div className="user-section">
                <Container>
                    <div className="row g-3">
                        <UserSidebarMenuSection />
                        <Outlet />
                    </div>
                </Container>
            </div>

            <FooterSection display="block" />
        </>
    );
}

export default UserAuthHandler;
