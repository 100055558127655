import React from 'react';
import { Link } from 'react-router-dom';
import CartIcon from '../../../assets/icons/Cart-white.svg';
import StarFill from '../../../assets/icons/star-fill.svg';
import StarNotFill from '../../../assets/icons/star-not-fill.svg';

export default function ItemTwo({ data }) {
    const fillStar = <img src={StarFill} style={{ width: 10, marginRight: 3 }} alt="" />;
    const fillNotStar = <img src={StarNotFill} style={{ width: 10, marginRight: 3 }} alt="" />;
    return (
        <div className="item">
            <div className="item-img-two">
                <img src={data?.img['0']} className="item-img-1" alt={data?.title} />
                <img src={data?.img['1']} className="item-img-2" alt={data?.title} />
            </div>
            <div className="item-info">
                <small>{data?.category}</small>
                <h5>
                    <Link to="/detail-full">{data?.title}</Link>
                </h5>
                <span>
                    {fillStar}
                    {fillStar}
                    {fillStar}
                    {fillStar}
                    {fillNotStar}(4.0)
                </span>
                <p style={{ fontWeight: 'bold' }}>${data?.price}</p>
                <div className="bar">
                    <div style={{ width: `${data?.id}0%` }} />
                </div>
                <p> Sold {data?.id}/10</p>
                <button type="button">
                    <Link to=".">
                        <img src={CartIcon} alt="Cart" />
                        Add
                    </Link>
                </button>
            </div>
        </div>
    );
}
