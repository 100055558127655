import React from 'react';
import Breadcrumb from '../../../components/section/Breadcrumb';
import CategorySectionLeftSide from '../section/CategorySectionLeftSide';
import FooterSection from '../section/FooterSection';
import HeaderSection from '../section/HeaderSection';
import OptionSection from '../section/OptionSection';
import SubscribeSection from '../section/SubscribeSection';

export default function CategoryPageLeftBar() {
    return (
        <>
            <HeaderSection />
            <Breadcrumb title="Category" />
            <CategorySectionLeftSide />
            <SubscribeSection />
            <OptionSection />
            <FooterSection />
        </>
    );
}
