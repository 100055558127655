import React from 'react';
import { Link } from 'react-router-dom';

import StarFill from '../../../assets/icons/star-fill.svg';
import StarNotFill from '../../../assets/icons/star-not-fill.svg';

export default function ItemSidebar({ data }) {
    const fillStar = <img src={StarFill} style={{ width: 10, marginRight: 3 }} alt="" />;
    const fillNotStar = <img src={StarNotFill} style={{ width: 10, marginRight: 3 }} alt="" />;
    return (
        <div className="detail-sidebar-item">
            <div className="detail-sidebar-item-img">
                <img src={data?.img['3']} alt="" />
            </div>
            <div className="detail-sidebar-item-info">
                <Link to="/detail-full">
                    <h5>{data?.title}</h5>
                </Link>
                <small>
                    {fillStar}
                    {fillStar}
                    {fillStar}
                    {fillStar}
                    {fillNotStar}(4)
                </small>
                <h6>${data?.price}</h6>
            </div>
        </div>
    );
}
