import React from 'react';
import ProductData from '../../../../assets/data/ProductData';

function UsersOrderPage() {
    return (
        <div className="col-12 col-lg-9 col-xl-9 col-md-9">
            <div className="user-section-title">Order History</div>
            <div className="user-section-order">
                <table>
                    <thead>
                        <th>Products</th>
                        <th>Unit Price</th>
                        <th>Quantity</th>
                        <th>SubTotal</th>
                    </thead>
                    <tbody>
                        {ProductData?.slice(0, 10).map((row) => (
                            <tr key={row?.id}>
                                <td>
                                    <img src={row?.img['0']} alt={row?.title} /> {row?.title}
                                </td>
                                <td>{row?.price}</td>
                                <td>10</td>
                                <td>{row?.price}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default UsersOrderPage;
