import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function LogoutPage() {
    const navigate = useNavigate();
    useEffect(() => {
        localStorage.clear();
        navigate('/');
    });

    return <div />;
}

export default LogoutPage;
