import React from 'react';
import OptionData from '../../../../assets/data/OptionData';

export default function OptionSection() {
    return (
        <div className="container">
            <div className="option-section">
                <div className="option-section-list animate fadeInLeft point-five-delay">
                    {OptionData?.map((row) => (
                        <div className="option-section-item " key={row.id}>
                            <img src={row?.icon} alt={row?.title} />
                            <div className="">
                                <h5>{row?.title}</h5>
                                <small>{row?.subTitle}</small>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
