import Icon1 from '../icons/icon-1.svg';
import Icon2 from '../icons/icon-2.svg';
import Icon3 from '../icons/icon-3.svg';

const VendorData = [
    {
        id: 100001,
        title: 'Nature Food',
        icon: Icon1,
        address: '47 W 13th St, New York, NY 10011, USA',
        contact: '0123456789',
        year: '2022',
    },
    {
        id: 100002,
        title: 'Alex Store',
        icon: Icon2,
        address: '47 W 13th St, New York, NY 10011, USA',
        contact: '0123456789',
        year: '2022',
    },
    {
        id: 100003,
        title: 'NY Shop',
        icon: Icon3,
        address: '47 W 13th St, New York, NY 10011, USA',
        contact: '0123456789',
        year: '2022',
    },
    {
        id: 100004,
        title: 'Fresh Food',
        icon: Icon3,
        address: '47 W 13th St, New York, NY 10011, USA',
        contact: '0123456789',
        year: '2022',
    },
    {
        id: 100005,
        title: 'Grocery Food',
        icon: Icon3,
        address: '47 W 13th St, New York, NY 10011, USA',
        contact: '0123456789',
        year: '2022',
    },
    {
        id: 100006,
        title: 'Sea Food',
        icon: Icon3,
        address: '47 W 13th St, New York, NY 10011, USA',
        contact: '0123456789',
        year: '2022',
    },
    {
        id: 100007,
        title: 'Artic Food',
        icon: Icon3,
        address: '47 W 13th St, New York, NY 10011, USA',
        contact: '0123456789',
        year: '2022',
    },
    {
        id: 100008,
        title: 'Gorgia Food',
        icon: Icon3,
        address: '47 W 13th St, New York, NY 10011, USA',
        contact: '0123456789',
        year: '2022',
    },
    {
        id: 100009,
        title: 'Texas Food',
        icon: Icon3,
        address: '47 W 13th St, New York, NY 10011, USA',
        contact: '0123456789',
        year: '2022',
    },
    {
        id: 100010,
        title: 'Ohio Food',
        icon: Icon3,
        address: '47 W 13th St, New York, NY 10011, USA',
        contact: '0123456789',
        year: '2022',
    },
];
export default VendorData;
