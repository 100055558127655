import React from 'react';
import Breadcrumb from '../../../components/section/Breadcrumb';
import CheckoutSection from '../section/CheckoutSection';
import FooterSection from '../section/FooterSection';
import HeaderSection from '../section/HeaderSection';
import OptionSection from '../section/OptionSection';

export default function CheckoutPage() {
    return (
        <>
            <HeaderSection />
            <Breadcrumb title="Checkout" />
            <CheckoutSection />
            <OptionSection />
            <FooterSection />
        </>
    );
}
