import React from 'react';
import Breadcrumb from '../../../components/section/Breadcrumb';
import BlogDetailSection from '../section/BlogDetailSection';
import FooterSection from '../section/FooterSection';
import HeaderSection from '../section/HeaderSection';
import OptionSection from '../section/OptionSection';
import SubscribeSection from '../section/SubscribeSection';

function BlogDetailPage() {
    return (
        <>
            <HeaderSection />
            <Breadcrumb title="Blogs" />
            <BlogDetailSection />
            <SubscribeSection />
            <OptionSection />
            <FooterSection />
        </>
    );
}

export default BlogDetailPage;
