import React from 'react';
import { Link } from 'react-router-dom';
import Pro1 from '../../../../assets/images/products/product-1-1.png';
import Pro11 from '../../../../assets/images/products/product-1-2.png';
import Pro10 from '../../../../assets/images/products/product-10-1.jpg';
import Pro12 from '../../../../assets/images/products/product-12-1.jpg';
import Pro121 from '../../../../assets/images/products/product-12-2.jpg';
import Pro16 from '../../../../assets/images/products/product-16-1.jpg';

function BlogSection() {
    return (
        <div className="blog-section">
            <div className="container">
                <div className="row g-4">
                    <div className="col-12">
                        <div className="section-title">
                            <h1>Blogs</h1>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-xl-3 col-md-6">
                        <div className="blog-section-item">
                            <img src={Pro1} alt="" />
                            <div className="blog-section-item-content">
                                <h3 className="title">
                                    How to eat junk food: A guide for conflicted humans
                                </h3>
                                <p className="description">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                    Adipisci id molestiae necessitatibus,possimus quos saepe
                                    soluta. Assumenda laborum officia rem
                                </p>
                                <Link to="/blog/detail" className="read-more">
                                    read more
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-xl-3 col-md-6">
                        <div className="blog-section-item">
                            <img src={Pro11} alt="" />
                            <div className="blog-section-item-content">
                                <h3 className="title">
                                    Superstition, sciencestition, and how to stop overthinking
                                    food.
                                </h3>
                                <p className="description">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                    Adipisci id molestiae necessitatibus,possimus quos saepe
                                    soluta. Assumenda laborum officia rem
                                </p>
                                <Link to="/blog/detail" className="read-more">
                                    read more
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-xl-3 col-md-6">
                        <div className="blog-section-item">
                            <img src={Pro10} alt="" />
                            <div className="blog-section-item-content">
                                <h3 className="title">
                                    Menopause and sleep: The struggle is real (and, by the way,
                                    so are these solutions)
                                </h3>
                                <p className="description">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                    Adipisci id molestiae necessitatibus,possimus quos saepe
                                    soluta. Assumenda laborum officia rem
                                </p>
                                <Link to="/blog/detail" className="read-more">
                                    read more
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-xl-3 col-md-6">
                        <div className="blog-section-item">
                            <img src={Pro12} alt="" />
                            <div className="blog-section-item-content">
                                <h3 className="title">
                                    Get your energy back: 3 surprising experiments that can
                                    invigorate your life.
                                </h3>
                                <p className="description">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                    Adipisci id molestiae necessitatibus,possimus quos saepe
                                    soluta. Assumenda laborum officia rem
                                </p>
                                <Link to="/blog/detail" className="read-more">
                                    read more
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-xl-3 col-md-6">
                        <div className="blog-section-item">
                            <img src={Pro121} alt="" />
                            <div className="blog-section-item-content">
                                <h3 className="title">
                                    Do nutrition and health coaches need insurance?
                                </h3>
                                <p className="description">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                    Adipisci id molestiae necessitatibus,possimus quos saepe
                                    soluta. Assumenda laborum officia rem
                                </p>
                                <Link to="/blog/detail" className="read-more">
                                    read more
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-xl-3 col-md-6">
                        <div className="blog-section-item">
                            <img src={Pro16} alt="" />
                            <div className="blog-section-item-content">
                                <h3 className="title">
                                    How to sleep better—when nothing helps you sleep better.
                                </h3>
                                <p className="description">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                    Adipisci id molestiae necessitatibus,possimus quos saepe
                                    soluta. Assumenda laborum officia rem
                                </p>
                                <Link to="/blog/detail" className="read-more">
                                    read more
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-xl-3 col-md-6">
                        <div className="blog-section-item">
                            <img src={Pro1} alt="" />
                            <div className="blog-section-item-content">
                                <h3 className="title">
                                    How to eat junk food: A guide for conflicted humans
                                </h3>
                                <p className="description">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                    Adipisci id molestiae necessitatibus,possimus quos saepe
                                    soluta. Assumenda laborum officia rem
                                </p>
                                <Link to="/blog/detail" className="read-more">
                                    read more
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-xl-3 col-md-6">
                        <div className="blog-section-item">
                            <img src={Pro11} alt="" />
                            <div className="blog-section-item-content">
                                <h3 className="title">
                                    Superstition, sciencestition, and how to stop overthinking
                                    food.
                                </h3>
                                <p className="description">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                    Adipisci id molestiae necessitatibus,possimus quos saepe
                                    soluta. Assumenda laborum officia rem
                                </p>
                                <Link to="/blog/detail" className="read-more">
                                    read more
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlogSection;
