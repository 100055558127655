import Icon1 from '../icons/icon-1.svg';
import Icon2 from '../icons/icon-2.svg';
import Icon3 from '../icons/icon-3.svg';

const CategoryData = [
    {
        id: 100001,
        title: 'Milks & Dairies',
        icon: Icon1,
    },
    {
        id: 100002,
        title: 'Fresh Fruit',
        icon: Icon2,
    },
    {
        id: 100003,
        title: 'Fish & Meat',
        icon: Icon3,
    },
    {
        id: 100004,
        title: 'Pet Food',
        icon: Icon3,
    },
];
export default CategoryData;
