import Banner1 from '../images/banner-5.png';
import Banner2 from '../images/banner-6.png';
import Banner3 from '../images/banner-7.png';
import Banner4 from '../images/banner-8.png';
import Pro1 from '../images/products/product-1-1.png';
import Pro11 from '../images/products/product-1-2.png';
import Pro10 from '../images/products/product-10-1.jpg';
import Pro101 from '../images/products/product-10-2.jpg';
import Pro111 from '../images/products/product-11-2.jpg';
import Pro12 from '../images/products/product-12-1.jpg';
import Pro121 from '../images/products/product-12-2.jpg';
import Pro13 from '../images/products/product-13-1.jpg';
import Pro131 from '../images/products/product-13-2.jpg';
import Pro14 from '../images/products/product-14-1.jpg';
import Pro141 from '../images/products/product-14-2.jpg';
import Pro15 from '../images/products/product-15-1.jpg';
import Pro151 from '../images/products/product-15-2.jpg';
import Pro16 from '../images/products/product-16-1.jpg';
import Pro161 from '../images/products/product-16-2.jpg';
import Pro2 from '../images/products/product-2-1.png';
import Pro21 from '../images/products/product-2-2.png';
import Pro3 from '../images/products/product-3-1.png';
import Pro31 from '../images/products/product-3-2.png';
import Pro4 from '../images/products/product-4-1.jpg';
import Pro41 from '../images/products/product-4-2.jpg';
import Pro5 from '../images/products/product-5-1.jpg';
import Pro51 from '../images/products/product-5-2.jpg';
import Pro6 from '../images/products/product-6-1.jpg';
import Pro61 from '../images/products/product-6-2.jpg';
import Pro7 from '../images/products/product-7-1.png';
import Pro71 from '../images/products/product-7-2.png';
import Pro9 from '../images/products/product-9-1.jpg';
import Pro91 from '../images/products/product-9-2.jpg';
import Thum1 from '../images/thumbnail-1.jpg';
import Thum9 from '../images/thumbnail-10.jpg';
import Thum10 from '../images/thumbnail-12.jpg';
import Thum2 from '../images/thumbnail-2.jpg';
import Thum3 from '../images/thumbnail-3.jpg';
import Thum4 from '../images/thumbnail-5.jpg';
import Thum5 from '../images/thumbnail-6.jpg';
import Thum6 from '../images/thumbnail-7.jpg';
import Thum7 from '../images/thumbnail-8.jpg';
import Thum8 from '../images/thumbnail-9.jpg';

const ProductData = [
    {
        id: '1',
        title: 'Seeds of Change Organic Quinoa, Brown, & Red Rice',
        img: [Pro1, Pro11, Banner1, Thum1],
        category: 'Grocery',
        price: '101.00',
    },
    {
        id: '2',
        title: 'All Natural Italian-Style Chicken Meatballs',
        img: [Pro2, Pro21, Banner2, Thum2],
        category: 'Grocery',
        price: '101.00',
    },
    {
        id: '3',
        title: 'Angie’s Boomchickapop Sweet & Salty Kettle Corn',
        img: [Pro3, Pro31, Banner3, Thum3],
        category: 'Snack',
        price: '101.00',
    },
    {
        id: '4',
        title: 'Foster Farms Takeout Crispy Classic Buffalo Wings',
        img: [Pro4, Pro41, Banner4, Thum4],
        category: 'Vegetables',
        price: '101.00',
    },
    {
        id: '5',
        title: 'Blue Diamond Almonds Lightly Salted Vegetables',
        img: [Pro5, Pro51, Banner4, Thum5],
        category: 'Snack',
        price: '101.00',
    },
    {
        id: '6',
        title: 'Canada Dry Ginger Ale – 2 L Bottle - 200ml - 400g',
        img: [Pro6, Pro61, Banner4, Thum6],
        category: 'Vegetables',
        price: '101.00',
    },
    {
        id: '7',
        title: 'Canada Dry Ginger Ale – 2 L Bottle - 200ml - 400g',
        img: [Pro7, Pro71, Banner4, Thum7],
        category: 'Snack',
        price: '101.00',
    },
    {
        id: '8',
        title: 'Encore Seafoods Stuffed Alaskan Salmon',
        img: [Pro4, Pro41, Banner4, Thum8],
        category: 'Snack',
        price: '101.00',
    },
    {
        id: '9',
        title: 'Gorton’s Beer Battered Fish Fillets with soft paper',
        img: [Pro9, Pro91, Banner4, Thum9],
        category: 'Snack',
        price: '101.00',
    },
    {
        id: '10',
        title: 'Haagen-Dazs Caramel Cone Ice Cream Ketchup',
        img: [Pro10, Pro101, Banner4, Thum10],
        category: 'Snack',
        price: '101.00',
    },
    {
        id: '11',
        title: 'Signature Wood-Fired Mushroom and Caramelized',
        img: [Pro11, Pro111, Banner4, Thum4],
        category: 'Snack',
        price: '101.00',
    },
    {
        id: '12',
        title: 'Simply Lemonade with Raspberry Juice',
        img: [Pro12, Pro121, Banner4, Thum4],
        category: 'Snack',
        price: '101.00',
    },
    {
        id: '13',
        title: 'Nestle Original Coffee-Mate Coffee Creamer',
        img: [Pro13, Pro131, Banner4, Thum10],
        category: 'Snack',
        price: '101.00',
    },
    {
        id: '14',
        title: 'Pepperidge Farm Farmhouse Hearty White Bread',
        img: [Pro14, Pro141, Banner4, Thum5],
        category: 'Snack',
        price: '101.00',
    },
    {
        id: '15',
        title: 'Naturally Flavored Cinnamon Vanilla Light Roast Coffee',
        img: [Pro15, Pro151, Banner4, Thum2],
        category: 'Snack',
        price: '101.00',
    },
    {
        id: '16',
        title: 'Organic Frozen Triple Berry Blend',
        img: [Pro16, Pro161, Banner4, Thum3],
        category: 'Snack',
        price: '101.00',
    },
];
export default ProductData;
