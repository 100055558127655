import React from 'react';
import Breadcrumb from '../../../components/section/Breadcrumb';
import BlogSection from '../section/BlogSection';
import FooterSection from '../section/FooterSection';
import HeaderSection from '../section/HeaderSection';
import OptionSection from '../section/OptionSection';
import SubscribeSection from '../section/SubscribeSection';

function BlogPage() {
    return (
        <>
            <HeaderSection />
            <Breadcrumb title="Blogs" />
            <BlogSection />
            <SubscribeSection />
            <OptionSection />
            <FooterSection />
        </>
    );
}

export default BlogPage;
