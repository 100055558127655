import React from 'react';
import Breadcrumb from '../../../components/section/Breadcrumb';
import FooterSection from '../section/FooterSection';
import HeaderSection from '../section/HeaderSection';
import OptionSection from '../section/OptionSection';
import ProductDetailSectionFull from '../section/ProductDetailSectionFull';
import SubscribeSection from '../section/SubscribeSection';

export default function DetailPageFull() {
    return (
        <>
            <HeaderSection />
            <Breadcrumb title="Detail" />
            {/* Product Detail Start */}
            <div className="container mb-5">
                <div className="row">
                    <ProductDetailSectionFull />
                </div>
            </div>
            <SubscribeSection />
            <OptionSection />
            <FooterSection />
        </>
    );
}
