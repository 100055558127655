import { faPhone, faSearchLocation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import StarFill from '../../../assets/icons/star-fill.svg';
import StarNotFill from '../../../assets/icons/star-not-fill.svg';

function VendorListGrid({ data }) {
    const fillStar = <img src={StarFill} style={{ width: 10, marginRight: 3 }} alt="" />;
    const fillNotStar = <img src={StarNotFill} style={{ width: 10, marginRight: 3 }} alt="" />;
    return (
        <div className="vendor-section">
            <div className="vendor-section-img">
                <img src={data?.icon} alt="" />
            </div>
            <div className="vendor-section-info">
                <div className="vendor-section-info-head">
                    <small>Since {data?.year}</small>
                    <p>{data?.title}</p>
                    <h6>
                        {fillStar}
                        {fillStar}
                        {fillStar}
                        {fillStar}
                        {fillNotStar}
                    </h6>
                    <button type="button">100 Products</button>
                </div>
                <div className="vendor-section-info-body">
                    <p>
                        <FontAwesomeIcon size="lg" icon={faSearchLocation} /> {data?.address}
                    </p>
                    <p>
                        <FontAwesomeIcon size="lg" icon={faPhone} /> {data?.contact}
                    </p>
                    <Link to="/vendor/detail">View Store</Link>
                </div>
            </div>
        </div>
    );
}

export default VendorListGrid;
