import React from 'react';
import Breadcrumb from '../../../components/section/Breadcrumb';
import ContactSection from '../section/ContactSection';
import FooterSection from '../section/FooterSection';
import HeaderSection from '../section/HeaderSection';

function ContactPage() {
    return (
        <>
            <HeaderSection />
            <Breadcrumb title="Contact" />
            <ContactSection />
            <FooterSection />
        </>
    );
}

export default ContactPage;
