import React from 'react';
import ProductData from '../../../../assets/data/ProductData';

function UsersDashboardPage() {
    return (
        <div className="col-12 col-lg-9 col-xl-9 col-md-9">
            <div className="row g-4">
                <div className="col-12 col-lg-6 col-xl-6 col-md-6">
                    <div className="dashboard-profile">
                        <h5>Profile Info</h5>
                        <table>
                            <tr>
                                <td>Name</td>
                                <td>: Mr.React Js</td>
                            </tr>
                            <tr>
                                <td>Phone Number</td>
                                <td>: 01934335477</td>
                            </tr>
                            <tr>
                                <td>Address</td>
                                <td>: react@gmail.com</td>
                            </tr>
                            <tr>
                                <td>Status</td>
                                <td>: Active</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-6 col-md-6">
                    <div className="dashboard-profile">
                        <h5>Address Info</h5>
                        <table>
                            <tr>
                                <td>Name</td>
                                <td>: Mr.React Js</td>
                            </tr>
                            <tr>
                                <td>Phone Number</td>
                                <td>: 01934335477</td>
                            </tr>
                            <tr>
                                <td>Email Address</td>
                                <td>: House#49, Road#29, Mirpur,Dhaka</td>
                            </tr>
                            <tr>
                                <td>Status</td>
                                <td>: Default </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="col-12">
                    <div className="dashboard-history">
                        <h5>Recent Orders</h5>
                        <table>
                            <thead>
                                <th>Products</th>
                                <th>Unit Price</th>
                                <th>Quantity</th>
                                <th>SubTotal</th>
                            </thead>
                            <tbody>
                                {ProductData?.slice(0, 7).map((row) => (
                                    <tr key={row?.id}>
                                        <td>
                                            <img src={row?.img['0']} alt={row?.title} />{' '}
                                            {row?.title}
                                        </td>
                                        <td>{row?.price}</td>
                                        <td>10</td>
                                        <td>{row?.price}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UsersDashboardPage;
