import React from 'react';
import WelcomeModal from '../../../components/section/WelcomeModal';
import DailyBestSellSection from '../section/DailyBestSellSection';
import DealsDaySection from '../section/DealsDaySection';
import FeaturedCategoriesSectionTwo from '../section/FeaturedCategoriesSectionTwo';
import FooterSection from '../section/FooterSection';
import HeaderSection from '../section/HeaderSection';
import OptionSection from '../section/OptionSection';
import OtherSection from '../section/OtherSection';
import PopularProductSectionTwo from '../section/PopularProductSectionTwo';
import ProductDetailSidebar from '../section/ProductDetailSidebar';
import SliderSection from '../section/SliderSection';
import SubscribeSection from '../section/SubscribeSection';

export default function IndexPageThree() {
    return (
        <>
            <HeaderSection />
            <div className="container">
                <div className="row">
                    <ProductDetailSidebar />
                    <div className="col-lg-9 col-xl-9 col-12 p-0">
                        <SliderSection />
                        <PopularProductSectionTwo />
                    </div>
                </div>
            </div>
            <DealsDaySection />
            <FeaturedCategoriesSectionTwo />
            <DailyBestSellSection />
            <OtherSection />
            <SubscribeSection />
            <OptionSection />
            <FooterSection />
            <WelcomeModal />
        </>
    );
}
