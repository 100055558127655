import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import SendIcon from '../../../../assets/icons/Send-icon.svg';
import Slider1 from '../../../../assets/images/slider-1.png';
import Slider2 from '../../../../assets/images/slider-2.png';

export default function SliderSection() {
    return (
        <div className="slider-section">
            <div className="container">
                <Carousel>
                    <Carousel.Item>
                        <img className="d-block w-100" src={Slider1} alt="First slide" />
                        <Carousel.Caption>
                            <h3>
                                Don’t miss amazing
                                <br />
                                Grocery Deals
                            </h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                            <div className="subscribe-input">
                                <div className="input-group mb-3">
                                    <span className="input-group-icon">
                                        <img
                                            src={SendIcon}
                                            alt=""
                                            style={{ width: 20, height: 'auto' }}
                                        />
                                    </span>
                                    <input
                                        type="text"
                                        aria-label=""
                                        placeholder="Your Email Address"
                                    />
                                    <span className="input-group-subscribe">Subscribe</span>
                                </div>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" src={Slider2} alt="Second slide" />

                        <Carousel.Caption>
                            <h3>
                                Fresh Vegetables
                                <br />
                                Big Discount
                            </h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            <button type="button">Discover</button>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
        </div>
    );
}
