import React from 'react';
import Breadcrumb from '../../../components/section/Breadcrumb';
import FooterSection from '../section/FooterSection';
import HeaderSection from '../section/HeaderSection';
import OptionSection from '../section/OptionSection';
import ProductDetailSection from '../section/ProductDetailSection';
import ProductDetailSidebar from '../section/ProductDetailSidebar';
import SubscribeSection from '../section/SubscribeSection';

export default function DetailPageLeftBar() {
    return (
        <>
            <HeaderSection />
            <Breadcrumb title="Detail" />
            {/* Product Detail Start */}
            <div className="container mb-5">
                <div className="row">
                    <ProductDetailSidebar />
                    <ProductDetailSection />
                </div>
            </div>
            <SubscribeSection />
            <OptionSection />
            <FooterSection />
        </>
    );
}
