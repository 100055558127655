import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function UserSidebarMenuSection() {
    const { pathname } = useLocation();

    return (
        <div className="col-3 d-none d-lg-block d-xl-block d-md-block">
            <div className="user-section-menu">
                <ul>
                    <li className={pathname === '/user/dashboard/' ? 'active-menu' : ''}>
                        <Link to="/user/dashboard/">Dashboard</Link>
                    </li>
                    <li className={pathname === '/user/profile/' ? 'active-menu' : ''}>
                        <Link to="/user/profile/">Profile</Link>
                    </li>
                    <li className={pathname === '/user/address/' ? 'active-menu' : ''}>
                        <Link to="/user/address/">Address</Link>
                    </li>
                    <li className={pathname === '/user/order/' ? 'active-menu' : ''}>
                        <Link to="/user/order/">Orders</Link>
                    </li>
                    <li className={pathname === '/user/wishlist/' ? 'active-menu' : ''}>
                        <Link to="/user/wishlist/">Wishlist</Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default UserSidebarMenuSection;
