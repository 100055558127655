import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import ProductData from '../../../../assets/data/ProductData';
import bestBanner from '../../../../assets/images/banner-4.png';
import CarouselItem from '../../../components/section/CarouselItem';

export default function DailyBestSellSection() {
    return (
        <div className="container">
            <div className="best-sell-section">
                <div className="best-sell-section-title">
                    <h4>Daily Best Sells</h4>
                </div>
                <div className="best-sell-section-list">
                    <div className="best-sell-section-banner">
                        <img src={bestBanner} alt="" />
                        <div className="best-sell-section-banner-info">
                            <h4>
                                <span style={{ background: 'white' }}>
                                    Bring nature into your home
                                </span>
                            </h4>
                            <Link to="/">Shop Now</Link>
                        </div>
                    </div>
                    <div className="best-sell-section-tab">
                        <CarouselItem ProductData={ProductData} />
                    </div>
                </div>
            </div>
        </div>
    );
}
