import React, { useState } from 'react';

import ProductData from '../../../../assets/data/ProductData';
import OrderConfirmModal from '../../../components/section/OrderConfirmModal';

export default function CheckoutSection() {
    const [show, setShow] = useState(false);
    const handleProceed = () => {
        setShow(true);
    };
    return (
        <>
            <div className="container mb-5">
                <div className="row">
                    <div className="col-12">
                        <h3>Your Checkout</h3>
                        <p>There are 3 products in your cart</p>
                    </div>
                    <div className="col-12 col-lg-8 col-xl-8 col-md-6">
                        <div className="cart-detail-list">
                            <table>
                                <thead>
                                    <th>Products</th>
                                    <th>Unit Price</th>
                                    <th>Quantity</th>
                                    <th>SubTotal</th>
                                </thead>
                                <tbody>
                                    {ProductData?.slice(0, 10).map((row) => (
                                        <tr>
                                            <td>{row?.title}</td>
                                            <td>{row?.price}</td>
                                            <td>1</td>
                                            <td>{row?.price}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-xl-4 col-md-6">
                        <div className="cart-detail-summery">
                            <div className="cart-detail-summery-title">
                                <h3>Shipping & Billing</h3>
                            </div>
                            <div className="checkout-summary-top">
                                <p>Sourav Mallick</p>
                                <button type="button">Change</button>
                            </div>
                            <div className="checkout-summary-address">
                                <button type="button" className="address-type-btn">
                                    Home
                                </button>
                                <p>
                                    Mirpur, Mirpur
                                    <br />
                                    Dhaka, Dhaka, BD, 1200
                                    <br />
                                    <span>8801934335477</span>, souravcmt@gmail.com
                                    <br />
                                </p>
                            </div>
                            <div className="checkout-summary-top">
                                <p>Bill to the same address</p>
                            </div>
                            <div className="checkout-summary-top">
                                <p>8801934335477</p>
                                <button type="button">Edit</button>
                            </div>
                            <div className="checkout-summary-top">
                                <p>souravcmt@gmail.com</p>
                                <button type="button">Edit</button>
                            </div>
                            <div className="checkout-summary-divider" />
                            <div className="cart-detail-summery-title">
                                <h3>Order Summary</h3>
                            </div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Subtotal (1 items)</td>
                                        <td className="text-right">৳ 196.00</td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td className="text-right">৳ 196.00</td>
                                    </tr>
                                </tbody>
                            </table>
                            <button type="button" onClick={handleProceed}>
                                Order Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {show ? <OrderConfirmModal /> : null}
        </>
    );
}
