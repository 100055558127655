import React from 'react';
import ProductData from '../../../../assets/data/ProductData';
import ItemList from '../../../components/section/ItemList';
import ProductDetailSidebar from './ProductDetailSidebar';

function CategorySectionListRightSide() {
    return (
        <div className="container">
            <div className="row mb-5">
                <div className="col-12 col-lg-9 col-xl-9">
                    <div className="row g-4">
                        <div className="col-12">
                            <div className="category-section-title">
                                <p>We found 29 items for you!</p>
                                <select>
                                    <option>Show :10</option>
                                    <option>50</option>
                                    <option>100</option>
                                    <option>150</option>
                                </select>
                                <select>
                                    <option>Sort By :Feature</option>
                                    <option>50</option>
                                    <option>100</option>
                                    <option>150</option>
                                </select>
                            </div>
                        </div>
                        {ProductData?.slice(0, 10).map((row) => (
                            <div className="col-12" key={`all-${row?.id}`}>
                                <ItemList data={row} />
                            </div>
                        ))}
                    </div>
                </div>
                <ProductDetailSidebar />
            </div>
        </div>
    );
}

export default CategorySectionListRightSide;
