/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/images/Logo.svg';

function HeaderSideBarMenu({ show, setShow }) {
    return (
        <Modal
            show={show}
            onHide={() => {
                setShow(false);
            }}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            size="xl"
            className="modal left "
        >
            <div className="modal-content">
                <Modal.Header
                    closeButton={
                        <button type="button" className="close btn btn-close">
                            <span aria-hidden="true">×</span>
                            <span className="sr-only">Close</span>
                        </button>
                    }
                >
                    <Modal.Title>
                        <img src={Logo} alt="Logo" style={{ width: 125 }} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="sidebar-menu-tab">
                        <ul className="mobile-menu">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link to="/">About</Link>
                            </li>
                            <li>
                                <Link to="/">Shop</Link>
                            </li>
                            <li>
                                <Link to="/">Vendor</Link>
                            </li>
                            <li>
                                <Link to="/">Blog</Link>
                            </li>
                            <li>
                                <Link to="/">Contact</Link>
                            </li>
                        </ul>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    );
}

export default HeaderSideBarMenu;
