import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Slider1 from '../../../../assets/images/slider-1.png';
import Slider2 from '../../../../assets/images/slider-2.png';

function BlogDetailSection() {
    return (
        <div className="blog-detail-section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 col-lg-3 col-xl-3 col-md-3 order-2 order-lg-1 order-xl-1 order-md-1 ">
                                <div className="blog-popular">
                                    <div className="blog-feature-title">
                                        <h3>Popular Post</h3>
                                        <hr />
                                    </div>
                                    <div className="blog-popular-list">
                                        <h6>Milks & Dairies</h6>
                                        <a href=".">
                                            Fish &amp; Food Sovereignty in Times of Crisis in our
                                            Country.
                                        </a>
                                        <hr />
                                    </div>
                                    <div className="blog-popular-list">
                                        <h6>Fresh Fruit</h6>
                                        <a href=".">
                                            Fish &amp; Food Sovereignty in Times of Crisis in our
                                            Country.
                                        </a>
                                        <hr />
                                    </div>
                                    <div className="blog-popular-list">
                                        <h6>Fish & Meat</h6>
                                        <a href=".">
                                            Fish &amp; Food Sovereignty in Times of Crisis in our
                                            Country.
                                        </a>
                                        <hr />
                                    </div>
                                    <div className="blog-popular-list">
                                        <h6>Pet Food</h6>
                                        <a href=".">
                                            Fish &amp; Food Sovereignty in Times of Crisis in our
                                            Country.
                                        </a>
                                        <hr />
                                    </div>
                                    <div className="blog-popular-list">
                                        <h6>Fresh Fruit</h6>
                                        <a href=".">
                                            Fish &amp; Food Sovereignty in Times of Crisis in our
                                            Country.
                                        </a>
                                        <hr />
                                    </div>
                                    <div className="blog-popular-list">
                                        <h6>Pet Food</h6>
                                        <a href=".">
                                            Fish &amp; Food Sovereignty in Times of Crisis in our
                                            Country.
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-9 col-xl-9 col-md-9 order-1 order-lg-2 order-xl-2 order-md-2  pr-2 mb-5">
                                <div className="blog-post-detail-info">
                                    <h6>Milks & Dairies</h6>
                                    <h2>
                                        Fish &amp; Food Sovereignty in Times of Crisis in our
                                        Country.
                                    </h2>
                                    <span>Cristopher Henry</span>
                                    <span>05 January 22</span>
                                    <span>0 Comment</span>
                                    <img src={Slider2} alt="" />
                                    <p>
                                        Guys! I am so excited to share this fall campaign we shot a
                                        couple weeks ago! I am always looking forward to fall
                                        fashion. There are endless layer options and countless
                                        accessories, but one of my favorite accessories is one that
                                        you’ll wear year-round – eyewear! Whether it’s eyeglasses or
                                        sunglasses, eyewear is really one piece that fully completes
                                        a look, and it’s crazy how a different pair of frames can
                                        totally transform a look.
                                    </p>
                                    <p>
                                        After I had Beckam my eyesight got worse! I don’t know if
                                        that’s a thing of if it was coincidence, or was just a part
                                        of aging, but my vision isn’t as strong as it used to be.
                                        Funny thing, in high school I ALWAYS wanted glasses.
                                    </p>
                                    <p>
                                        Now that I have a true need for them, I love getting to add
                                        glasses to my looks. To be honest, I somehow always misplace
                                        them though! I usually have my sunglasses spread out all
                                        around my car, house, and bags so I always have a pair
                                        somewhere, but I only had a few prescription glasses so I’ve
                                        been on the lookout for new frames to have them handy
                                    </p>
                                    <img src={Slider1} className="blog-detail-img-one" alt="" />

                                    <p>
                                        How you dress will always affect how you feel. Even when I’m
                                        working from home, I can tell a difference in my
                                        productivity those days that I get ready and the days I
                                        lounge in my sweats. It’s definitely a mental thing, but I
                                        think the same is true of glasses!
                                    </p>
                                    <p>
                                        How you dress will always affect how you feel. Even when I’m
                                        working from home, I can tell a difference in my
                                        productivity those days that I get ready and the days I
                                        lounge in my sweats. It’s definitely a mental thing, but I
                                        think the same is true of glasses!
                                    </p>

                                    <button type="button">Fresh Fruit</button>
                                    <button type="button">Fish & Meat</button>
                                    <button type="button">Pet Food</button>
                                </div>
                                <div className="blog-post-detail-pagination">
                                    <button type="button" className="prev-btn">
                                        <a href="." className="prev-next-btn-text">
                                            <FontAwesomeIcon size="lg" icon={faArrowLeft} />
                                            Previous
                                        </a>
                                    </button>
                                    <button type="button" className="next-btn">
                                        <a href="." className="prev-next-btn-text">
                                            Next <FontAwesomeIcon size="lg" icon={faArrowRight} />
                                        </a>
                                    </button>
                                </div>
                                <div className="blog-post-comment">
                                    <h4>2 Comment Found</h4>
                                    <div className="blog-post-comment-item">
                                        <div className="blog-post-comment-item-img">A</div>
                                        <div className="blog-post-comment-item-title">
                                            <h5>Amelia D. Clark</h5>
                                            <p>25 Mins Ago</p>
                                        </div>
                                        <div className="blog-post-comment-item-replay">
                                            <button type="button">Replay</button>
                                        </div>
                                        <div className="blog-post-comment-item-detail">
                                            I always get a ton of questions about my skincare
                                            routine, and I love sharing my favorite products but I
                                            think the biggest thing in getting great skin is being
                                            consistent! I think we are all really good about doing
                                            our full skincare routine when our skin is acting up,
                                            but it’s so easy to start skipping steps when your skin
                                            is back on track.
                                            <div className="blog-post-comment-item-divider" />
                                        </div>
                                    </div>
                                    <div className="blog-post-comment-item">
                                        <div className="blog-post-comment-item-img">B</div>
                                        <div className="blog-post-comment-item-title">
                                            <h5>Amelia D. Clark</h5>
                                            <p>25 Mins Ago</p>
                                        </div>
                                        <div className="blog-post-comment-item-replay">
                                            <button type="button">Replay</button>
                                        </div>
                                        <div className="blog-post-comment-item-detail">
                                            I always get a ton of questions about my skincare
                                            routine, and I love sharing my favorite products but I
                                            think the biggest thing in getting great skin is being
                                            consistent! I think we are all really good about doing
                                            our full skincare routine when our skin is acting up,
                                            but it’s so easy to start skipping steps when your skin
                                            is back on track.
                                            <div className="blog-post-comment-item-divider" />
                                        </div>
                                    </div>
                                    <h4>2 Comment Found</h4>
                                    <div className="blog-post-comment-item">
                                        <div className="blog-post-comment-item-input">
                                            <input type="text" placeholder="Full Name" />
                                        </div>
                                        <div className="blog-post-comment-item-input b-p-c-m">
                                            <input type="text" placeholder="Email Address" />
                                        </div>
                                        <div className="blog-post-comment-item-textarea">
                                            <textarea
                                                name=""
                                                placeholder="Wrote Your Comment Here"
                                            />
                                        </div>
                                        <div className="blog-post-comment-item-button">
                                            <button type="submit">Post Comment</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlogDetailSection;
