import React from 'react';
import Countdown from 'react-countdown';
import { Link } from 'react-router-dom';
import CartIcon from '../../../assets/icons/Cart-white.svg';
import StarFill from '../../../assets/icons/star-fill.svg';
import StarNotFill from '../../../assets/icons/star-not-fill.svg';

const renderer = ({ days, hours, minutes, seconds }) => (
    // Render a countdown
    <ul>
        <li>
            <h6> {days}</h6>
            <h6> Days</h6>
        </li>
        <li>
            <h6> {hours}</h6>
            <h6> Hours</h6>
        </li>
        <li>
            <h6> {minutes}</h6>
            <h6> Mins</h6>
        </li>
        <li>
            <h6> {seconds}</h6>
            <h6> Sec</h6>
        </li>
    </ul>
);
export default function ItemThree({ data }) {
    const fillStar = <img src={StarFill} style={{ width: 10, marginRight: 3 }} alt="" />;
    const fillNotStar = <img src={StarNotFill} style={{ width: 10, marginRight: 3 }} alt="" />;
    return (
        <div className="item-three ">
            <div className="item-img-three">
                <img src={data?.img['2']} className="item-img-1" alt={data?.title} />
            </div>
            <div className="item-three-info">
                <div className="timmer">
                    <Countdown date={Date.now() + 500000000} renderer={renderer} />
                </div>
                <Link to="/detail-full" className="item-three-info-list">
                    <h5>{data?.title}</h5>
                    <span>
                        {fillStar}
                        {fillStar}
                        {fillStar}
                        {fillStar}
                        {fillNotStar}(4.0)
                    </span>
                    <p>Vendor</p>
                    <div className="price">
                        <p>${data?.price}</p>
                        <Link to=".">
                            <img src={CartIcon} alt="Cart" />
                            Add
                        </Link>
                    </div>
                </Link>
            </div>
        </div>
    );
}
