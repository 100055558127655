import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ProductData from '../../../../assets/data/ProductData';
import Item from '../../../components/section/Item';

export default function PopularProductSectionTwo() {
    return (
        <div className="container">
            <div className="popular-section">
                <div className="popular-section-title">
                    <h4>Popular Products</h4>
                </div>
                <div className="popular-section-title-tab">
                    <Tabs
                        defaultActiveKey="home"
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="home" title="All">
                            <div className="popular-section-list">
                                {ProductData?.slice(0, 10).map((row) => (
                                    <div className="list-item-two" key={`all-${row?.id}`}>
                                        <Item data={row} />
                                    </div>
                                ))}
                            </div>
                        </Tab>
                        <Tab eventKey="profile" title="Milks">
                            <div className="popular-section-list">
                                {ProductData?.slice(5, 15).map((row) => (
                                    <div className="list-item-two" key={`milk-${row?.id}`}>
                                        <Item data={row} />
                                    </div>
                                ))}
                            </div>
                        </Tab>
                        <Tab eventKey="contact" title="Meats">
                            <div className="popular-section-list">
                                {ProductData?.slice(6, 16).map((row) => (
                                    <div className="list-item-two" key={`meat-${row?.id}`}>
                                        <Item data={row} />
                                    </div>
                                ))}
                            </div>
                        </Tab>
                        <Tab eventKey="veg" title="Vegetables">
                            <div className="popular-section-list">
                                {ProductData?.slice(3, 13).map((row) => (
                                    <div className="list-item-two" key={`veg-${row?.id}`}>
                                        <Item data={row} />
                                    </div>
                                ))}
                            </div>
                        </Tab>
                        <Tab eventKey="fruits" title="Fruits">
                            <div className="popular-section-list">
                                {ProductData?.slice(2, 12).map((row) => (
                                    <div className="list-item-two" key={`fruit-${row?.id}`}>
                                        <Item data={row} />
                                    </div>
                                ))}
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}
