import React from 'react';
import { Link } from 'react-router-dom';
import CategoryData from '../../../assets/data/CategoryData';

function CategoryInfo() {
    return (
        <div className="category-sidebar">
            <ul>
                {CategoryData?.map((row1) => (
                    <li key={`row1${row1.id}`}>
                        <Link to="/category/list/left-side">
                            <img
                                src={row1?.icon}
                                alt={row1?.title}
                                style={{ maxWidth: 30, marginRight: 10 }}
                            />
                            {row1?.title}
                        </Link>
                    </li>
                ))}
            </ul>
            <ul>
                {CategoryData?.map((row2) => (
                    <li key={`row2${row2.id}`}>
                        <Link to="/category">
                            <img
                                src={row2?.icon}
                                alt={row2?.title}
                                style={{ maxWidth: 30, marginRight: 10 }}
                            />
                            {row2?.title}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default CategoryInfo;
