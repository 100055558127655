/* eslint-disable no-return-assign */
import React, { useRef } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ProductData from '../../../../assets/data/ProductData';
import Item from '../../../components/section/Item';

export default function PopularProductSection() {
    const myRef = useRef(0);
    console.log(myRef);
    return (
        <div className="container">
            <div className="popular-section">
                <div className="popular-section-title">
                    <h4>Popular Products</h4>
                </div>
                <div className="popular-section-title-tab">
                    <Tabs
                        defaultActiveKey="home"
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="home" title="All">
                            <div
                                className="popular-section-list  animate fadeInLeft point-five-delay"
                                style={{
                                    animationDdelay: '.5s',
                                    webkitAnimationDelay: '.5s',
                                    mozAnimationDelay: '.5s',
                                }}
                                ref={myRef}
                            >
                                {ProductData?.slice(0, 5).map((row, index) => (
                                    <div className="list-item" key={`all-${row?.id}`}>
                                        <Item data={row} index={index} />
                                    </div>
                                ))}
                            </div>
                            <div
                                className="popular-section-list  animate fadeInLeft one-point-two-delay"
                                ref={myRef}
                            >
                                {ProductData?.slice(5, 10).map((row, index) => (
                                    <div className="list-item" key={`all-${row?.id}`}>
                                        <Item data={row} index={index} />
                                    </div>
                                ))}
                            </div>
                        </Tab>
                        <Tab eventKey="profile" title="Milks">
                            <div className="popular-section-list  animate fadeInLeft point-five-delay">
                                {ProductData?.slice(5, 10).map((row, index) => (
                                    <div className="list-item" key={`milk-${row?.id}`}>
                                        <Item data={row} index={index} />
                                    </div>
                                ))}
                            </div>
                            <div className="popular-section-list  animate fadeInLeft one-point-two-delay">
                                {ProductData?.slice(6, 11).map((row, index) => (
                                    <div className="list-item" key={`milk-${row?.id}`}>
                                        <Item data={row} index={index} />
                                    </div>
                                ))}
                            </div>
                        </Tab>
                        <Tab eventKey="contact" title="Meats">
                            <div className="popular-section-list  animate fadeInLeft point-five-delay">
                                {ProductData?.slice(1, 6).map((row, index) => (
                                    <div className="list-item" key={`meat-${row?.id}`}>
                                        <Item data={row} index={index} />
                                    </div>
                                ))}
                            </div>
                            <div className="popular-section-list  animate fadeInLeft one-point-two-delay">
                                {ProductData?.slice(6, 11).map((row, index) => (
                                    <div className="list-item" key={`milk-${row?.id}`}>
                                        <Item data={row} index={index} />
                                    </div>
                                ))}
                            </div>
                        </Tab>
                        <Tab eventKey="veg" title="Vegetables">
                            <div className="popular-section-list  animate fadeInLeft point-five-delay">
                                {ProductData?.slice(3, 8).map((row, index) => (
                                    <div className="list-item" key={`veg-${row?.id}`}>
                                        <Item data={row} index={index} />
                                    </div>
                                ))}
                            </div>
                            <div className="popular-section-list  animate fadeInLeft one-point-two-delay">
                                {ProductData?.slice(6, 11).map((row, index) => (
                                    <div className="list-item" key={`milk-${row?.id}`}>
                                        <Item data={row} index={index} />
                                    </div>
                                ))}
                            </div>
                        </Tab>
                        <Tab eventKey="fruits" title="Fruits">
                            <div className="popular-section-list  animate fadeInLeft point-five-delay">
                                {ProductData?.slice(2, 7).map((row, index) => (
                                    <div className="list-item" key={`fruit-${row?.id}`}>
                                        <Item data={row} index={index} />
                                    </div>
                                ))}
                            </div>
                            <div className="popular-section-list  animate fadeInLeft one-point-two-delay">
                                {ProductData?.slice(6, 11).map((row, index) => (
                                    <div className="list-item" key={`milk-${row?.id}`}>
                                        <Item data={row} index={index} />
                                    </div>
                                ))}
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}
