import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AboutPage from './app/system/pages/AboutPage';
import BlogDetailPage from './app/system/pages/BlogDetailPage';
import BlogPage from './app/system/pages/BlogPage';
import CartPage from './app/system/pages/CartPage';
import CategoryPage from './app/system/pages/CategoryPage';
import CategoryPageLeftBar from './app/system/pages/CategoryPageLeftBar';
import CategoryPageListLeftBar from './app/system/pages/CategoryPageListLeftBar';
import CategoryPageListRightBar from './app/system/pages/CategoryPageListRightBar';
import CheckoutPage from './app/system/pages/CheckoutPage';
import ContactPage from './app/system/pages/ContactPage';
import DetailPage from './app/system/pages/DetailPage';
import DetailPageFull from './app/system/pages/DetailPageFull';
import DetailPageLeftBar from './app/system/pages/DetailPageLeftBar';
import IndexPage from './app/system/pages/IndexPage';
import IndexPageThree from './app/system/pages/IndexPageThree';
import IndexPageTwo from './app/system/pages/IndexPageTwo';
import LoginPage from './app/system/pages/LoginPage';
import LogoutPage from './app/system/pages/LogoutPage';
import VendorDetailPage from './app/system/pages/VendorDetailPage';
import VendorPage from './app/system/pages/VendorPage';
import UserAuthHandler from './app/users/components/UserAuthHandler';
import UserReviewPage from './app/users/pages/UserReviewPage';
import UsersAddressPage from './app/users/pages/UsersAddressPage';
import UsersDashboardPage from './app/users/pages/UsersDashboardPage';
import UsersOrderPage from './app/users/pages/UsersOrderPage';
import UsersProfilePage from './app/users/pages/UsersProfilePage';
import UserWishlistPage from './app/users/pages/UserWishlistPage';

export default function RouteHandler() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<IndexPage />} />
                <Route path="/home-2" element={<IndexPageTwo />} />
                <Route path="/home-3" element={<IndexPageThree />} />

                <Route path="/detail" element={<DetailPage />} />
                <Route path="/detail-full" element={<DetailPageFull />} />
                <Route path="/detail-left" element={<DetailPageLeftBar />} />

                <Route path="/cart" element={<CartPage />} />
                <Route path="/checkout" element={<CheckoutPage />} />

                <Route path="/category" element={<CategoryPage />} />
                <Route path="/category/left-side" element={<CategoryPageLeftBar />} />
                <Route path="/category/list/left-side" element={<CategoryPageListLeftBar />} />
                <Route path="/category/list/right-side" element={<CategoryPageListRightBar />} />

                <Route path="/about-us" element={<AboutPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/vendor" element={<VendorPage />} />
                <Route path="/vendor/detail/" element={<VendorDetailPage />} />
                <Route path="/blog/" element={<BlogPage />} />
                <Route path="/blog/detail/" element={<BlogDetailPage />} />

                <Route path="/user/*" element={<UserAuthHandler />}>
                    <Route path="dashboard/" element={<UsersDashboardPage />} />
                    <Route path="profile/" element={<UsersProfilePage />} />
                    <Route path="address/" element={<UsersAddressPage />} />
                    <Route path="order/" element={<UsersOrderPage type="all" />} />
                    <Route path="wishlist/" element={<UserWishlistPage />} />
                    <Route path="reviews/" element={<UserReviewPage type="pending" />} />
                    <Route path="reviews/history/" element={<UserReviewPage type="complete" />} />
                </Route>
                <Route path="/login/" element={<LoginPage />} />
                <Route path="/logout/" element={<LogoutPage />} />
            </Routes>
        </BrowserRouter>
    );
}
