import { Link } from 'react-router-dom';
import CategoryData from '../../../../assets/data/CategoryData';
import ProductData from '../../../../assets/data/ProductData';
import StarFill from '../../../../assets/icons/star-fill.svg';
import StarNotFill from '../../../../assets/icons/star-not-fill.svg';
import bestBanner from '../../../../assets/images/banner-4.png';
import ItemSidebar from '../../../components/section/ItemSidebar';

function VendorInfo() {
    const fillStar = <img src={StarFill} style={{ width: 10, marginRight: 3 }} alt="" />;
    const fillNotStar = <img src={StarNotFill} style={{ width: 10, marginRight: 3 }} alt="" />;
    return (
        <div className="vendor-detail-sidebar">
            <div className="vendor-detail-sidebar-img">
                <img src={bestBanner} alt="" />
            </div>
            <div className="vendor-detail-sidebar-info">
                <small>Science 2021</small>
                <h5>Fresh Food</h5>
                <h6>
                    {fillStar}
                    {fillStar}
                    {fillStar}
                    {fillStar}
                    {fillNotStar}
                </h6>
                <p>
                    Got a smooth, buttery spread in your fridge? Chances are good that it`s Good
                    Chef. This brand made list of the most popular grocery brands across the
                    country.
                </p>
                <p>47 W 13th St, New York, NY 10011, USA</p>
                <p>012345679</p>
                <button type="button">Contact Seller</button>
            </div>
        </div>
    );
}
export default function ProductDetailSidebar({ isVendor }) {
    return (
        <div className="col-12 col-lg-3 col-xl-3 d-none d-lg-block d-xl-block">
            {isVendor ? <VendorInfo /> : null}
            <div className="detail-sidebar">
                <div className="detail-sidebar-title">Filter</div>
                <div className="detail-sidebar-filter">
                    <h6>Color</h6>
                    <ul>
                        <li>
                            <input type="checkbox" /> Red
                        </li>
                        <li>
                            <input type="checkbox" /> Green
                        </li>
                        <li>
                            <input type="checkbox" /> White
                        </li>
                    </ul>
                    <h6>Item Condition</h6>
                    <ul>
                        <li>
                            <input type="checkbox" /> New
                        </li>
                        <li>
                            <input type="checkbox" /> Recondition
                        </li>
                        <li>
                            <input type="checkbox" /> Used
                        </li>
                    </ul>
                    <button type="button">Filter</button>
                </div>
            </div>
            <div className="detail-sidebar">
                <div className="detail-sidebar-title">Category</div>
                <div className="detail-sidebar-category">
                    {CategoryData?.map((row) => (
                        <div className="detail-sidebar-category-list" key={row?.id}>
                            <img src={row?.icon} alt={row?.title} />
                            <Link to="/category/list/left-side/">
                                <h5>{row?.title}</h5>
                            </Link>
                            <div className="">20</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="detail-sidebar">
                <div className="detail-sidebar-title">New Products</div>
                <div className="detail-sidebar-category">
                    {ProductData?.slice(0, 3).map((row) => (
                        <ItemSidebar data={row} key={row.id} />
                    ))}
                </div>
            </div>
            <div className="detail-sidebar-banner">
                <img src={bestBanner} alt="" />
                <div className="best-sell-section-banner-info">
                    <h4>
                        <span style={{ background: 'white' }}>Bring nature into your home</span>
                    </h4>
                    <Link to="/">Shop Now</Link>
                </div>
            </div>
        </div>
    );
}
