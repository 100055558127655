import { React } from 'react';

import LazyLoad from 'react-lazy-load';
import WelcomeModal from '../../../components/section/WelcomeModal';
import DailyBestSellSection from '../section/DailyBestSellSection';
import DealsDaySection from '../section/DealsDaySection';
import FeaturedCategoriesSection from '../section/FeaturedCategoriesSection';
import FooterSection from '../section/FooterSection';
import HeaderSection from '../section/HeaderSection';
import OptionSection from '../section/OptionSection';
import OtherSection from '../section/OtherSection';
import PopularProductSection from '../section/PopularProductSection';
import SliderSection from '../section/SliderSection';
import SubscribeSection from '../section/SubscribeSection';

export default function IndexPage() {
    return (
        <>
            <HeaderSection />
            <SliderSection />
            <LazyLoad height={1000}>
                <PopularProductSection />
            </LazyLoad>
            <LazyLoad height={650}>
                <FeaturedCategoriesSection />
            </LazyLoad>
            <LazyLoad height={510}>
                <DailyBestSellSection />
            </LazyLoad>
            <LazyLoad height={570}>
                <DealsDaySection />
            </LazyLoad>
            <LazyLoad height={500}>
                <OtherSection />
            </LazyLoad>
            <LazyLoad height={375}>
                <SubscribeSection />
            </LazyLoad>
            <LazyLoad height={175}>
                <OptionSection />
            </LazyLoad>
            <FooterSection />
            <WelcomeModal />
        </>
    );
}
