import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function OrderConfirmModalMain({ show, handleHideModal }) {
    const navigate = useNavigate();
    const handleOk = () => {
        navigate('/');
    };
    return (
        <Modal
            size="lg"
            className=""
            show={show}
            onHide={handleHideModal}
            aria-labelledby="example-modal-sizes-title-lg modal-confirm"
            dialogClassName="modal-confirm"
            top
            backdrop
        >
            <div className="modal-content">
                <div className="modal-header">
                    <div className="icon-box">
                        <FontAwesomeIcon size="xl" color="white" icon={faCheck} />
                    </div>
                    <h4 className="modal-title w-100">Awesome!</h4>
                </div>
                <div className="modal-body">
                    <p className="text-center">
                        Your order has been confirmed. Check your email for detials.
                    </p>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-success btn-block"
                        data-dismiss="modal"
                        onClick={handleOk}
                    >
                        OK
                    </button>
                </div>
            </div>
        </Modal>
    );
}

function OrderConfirmModal() {
    const [showModal, setShowModal] = useState(true);
    const [initialTime, setInitialTime] = useState(10000);
    const [startTimer, setStartTimer] = useState(true);
    const handleHideModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        if (initialTime > 0) {
            setTimeout(() => {
                setInitialTime(initialTime - 1);
            }, 1000);
        }

        if (initialTime === 0 && startTimer) {
            setStartTimer(false);
            setShowModal(false);
        }
    }, [initialTime, startTimer]);

    if (!showModal) {
        return null;
    }
    return <OrderConfirmModalMain show={showModal} handleHideModal={handleHideModal} />;
}

export default OrderConfirmModal;
