import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Carousel from 'react-multi-carousel';
import Team1 from '../../../../assets/images/team-1.png';
import Team2 from '../../../../assets/images/team-2.png';
import Team3 from '../../../../assets/images/team-3.png';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 1199, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};
function AboutSection() {
    return (
        <div className="container">
            <div className="about-section-info">
                <div className="row gx-5">
                    <div className="col-12 col-lg-6 col-xl-6 col-md-6">
                        <p>Over 150,000+ Orders</p>
                        <h1>
                            We are the Best
                            <br />
                            Grocery Growth <br />
                            Chain
                        </h1>
                    </div>
                    <div className="col-12 col-lg-6 col-xl-6 col-md-6">
                        <Tabs
                            defaultActiveKey="home"
                            transition={false}
                            id="noanim-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="home" title="Our Story">
                                <p className="about-section-info-detail">
                                    Everyone realizes why a new common language would be desirable:
                                    one could refuse to pay expensive translators. To achieve this,
                                    it would be necessary to have uniform grammar. The European
                                    languages are members of the same family. Their separate
                                    existence is a myth.
                                    <br />
                                    For science, music, sport, etc, Europe uses the same vocabulary.
                                    The languages only differ in their grammar, their pronunciation
                                    & their most common words.
                                </p>
                            </Tab>
                            <Tab eventKey="profile" title="Our Mission">
                                <p className="about-section-info-detail">
                                    Everyone realizes why a new common language would be desirable:
                                    one could refuse to pay expensive translators. To achieve this,
                                    it would be necessary to have uniform grammar. The European
                                    languages are members of the same family. Their separate
                                    existence is a myth.
                                    <br />
                                    For science, music, sport, etc, Europe uses the same vocabulary.
                                    The languages only differ in their grammar, their pronunciation
                                    & their most common words.
                                </p>
                            </Tab>
                            <Tab eventKey="contact" title="Our Vission">
                                <p className="about-section-info-detail">
                                    Everyone realizes why a new common language would be desirable:
                                    one could refuse to pay expensive translators. To achieve this,
                                    it would be necessary to have uniform grammar. The European
                                    languages are members of the same family. Their separate
                                    existence is a myth.
                                    <br />
                                    For science, music, sport, etc, Europe uses the same vocabulary.
                                    The languages only differ in their grammar, their pronunciation
                                    & their most common words.
                                </p>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
            <div className="about-section-info">
                <div className="row gx-5">
                    <div className="col-12 ">
                        <h3>Our Team</h3>
                    </div>
                    <div className="col-12">
                        <Carousel
                            responsive={responsive}
                            ssr
                            autoPlay
                            autoPlaySpeed={2000}
                            infinite
                        >
                            <div className="best-sell-section-item-list">
                                <div className="list-item">
                                    <div className="item-about">
                                        <div className="item-img-two">
                                            <img src={Team1} className="item-img-1" alt="Team1" />
                                            <img src={Team1} className="item-img-2" alt="Team1" />
                                        </div>
                                        <div className="item-info">
                                            <small>CEO</small>
                                            <h5>Mr.Alex</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="best-sell-section-item-list">
                                <div className="list-item">
                                    <div className="item-about">
                                        <div className="item-img-two">
                                            <img src={Team2} className="item-img-1" alt="Team1" />
                                            <img src={Team2} className="item-img-2" alt="Team1" />
                                        </div>
                                        <div className="item-info">
                                            <small>MD</small>
                                            <h5>Mr.Alex</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="best-sell-section-item-list">
                                <div className="list-item">
                                    <div className="item-about">
                                        <div className="item-img-two">
                                            <img src={Team3} className="item-img-1" alt="Team1" />
                                            <img src={Team3} className="item-img-2" alt="Team1" />
                                        </div>
                                        <div className="item-info">
                                            <small>Marketing Manager</small>
                                            <h5>Mr.Alex</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="best-sell-section-item-list">
                                <div className="list-item">
                                    <div className="item-about">
                                        <div className="item-img-two">
                                            <img src={Team1} className="item-img-1" alt="Team1" />
                                            <img src={Team1} className="item-img-2" alt="Team1" />
                                        </div>
                                        <div className="item-info">
                                            <small>Programmer</small>
                                            <h5>Mr.Alex</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="best-sell-section-item-list">
                                <div className="list-item">
                                    <div className="item-about">
                                        <div className="item-img-two">
                                            <img src={Team2} className="item-img-1" alt="Team1" />
                                            <img src={Team2} className="item-img-2" alt="Team1" />
                                        </div>
                                        <div className="item-info">
                                            <small>Designer</small>
                                            <h5>Mr.Alex</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="best-sell-section-item-list">
                                <div className="list-item">
                                    <div className="item-about">
                                        <div className="item-img-two">
                                            <img src={Team3} className="item-img-1" alt="Team1" />
                                            <img src={Team3} className="item-img-2" alt="Team1" />
                                        </div>
                                        <div className="item-info">
                                            <small>HR Manager</small>
                                            <h5>Mr.Alex</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
            <div className="about-section-info">
                <div className="row gx-5">
                    <div className="col-12 ">
                        <h3>Testmonial</h3>
                    </div>
                    <div className="col-12">
                        <Carousel
                            responsive={responsive}
                            ssr
                            autoPlay
                            autoPlaySpeed={2000}
                            infinite
                        >
                            <div className="best-sell-section-item-list">
                                <FontAwesomeIcon size="sm" color="#ECB3B0" icon={faQuoteLeft} />{' '}
                                Yesterday was the first time I visited this supermarket. I LOVED
                                IT!!!…so much so I’m going back today to get more things. EVERYTHING
                                WAS BEAUTIFUL. the staff was unusually pleasant and very
                                helpful…this will be my new supermarket.{' '}
                                <FontAwesomeIcon size="sm" color="#ECB3B0" icon={faQuoteRight} />
                            </div>
                            <div className="best-sell-section-item-list">
                                <FontAwesomeIcon size="sm" color="#ECB3B0" icon={faQuoteLeft} />{' '}
                                Love their organic high quality selections. Great customer service.
                                Recommend to all my friends and family!{'  '}
                                <FontAwesomeIcon size="sm" color="#ECB3B0" icon={faQuoteRight} />
                            </div>
                            <div className="best-sell-section-item-list">
                                <FontAwesomeIcon size="sm" color="#ECB3B0" icon={faQuoteLeft} />{' '}
                                This place is a must every morning for a healthy start. I get a
                                fresh drink made of fruits and vegetables for me,while I browse the
                                aisles for other interesting foods or snacks.{'  '}
                                <FontAwesomeIcon size="sm" color="#ECB3B0" icon={faQuoteRight} />
                            </div>
                            <div className="best-sell-section-item-list">
                                <FontAwesomeIcon size="sm" color="#ECB3B0" icon={faQuoteLeft} />{' '}
                                Very clean store, fresh food, reasonable prices, Smoothie bar, fresh
                                sushi, are some of my go to’s.{' '}
                                <FontAwesomeIcon size="sm" color="#ECB3B0" icon={faQuoteRight} />
                            </div>
                            <div className="best-sell-section-item-list">
                                <FontAwesomeIcon size="sm" color="#ECB3B0" icon={faQuoteLeft} /> By
                                far my favorite supermarket. Delicious smoothie and vegan cupcakes
                                and plenty of healthy foods to choose from.{'  '}
                                <FontAwesomeIcon size="sm" color="#ECB3B0" icon={faQuoteRight} />
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutSection;
