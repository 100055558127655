import React from 'react';
import Carousel from 'react-multi-carousel';
import ItemTwo from './ItemTwo';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 1199, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};
export default function CarouselItem({ ProductData }) {
    return (
        <Carousel responsive={responsive} ssr autoPlay autoPlaySpeed={2000} infinite>
            {ProductData?.slice(0, 10).map((row) => (
                <div className="best-sell-section-item-list" key={`best-d-${row?.id}`}>
                    <div className="list-item">
                        <ItemTwo data={row} />
                    </div>
                </div>
            ))}
        </Carousel>
    );
}
