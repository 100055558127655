import React from 'react';
import ProductData from '../../../../assets/data/ProductData';
import ItemFour from '../../../components/section/ItemFour';

export default function OtherSection() {
    return (
        <div className="container">
            <div className="other-section">
                <div className="other-section-list">
                    <div className="other-section-item cssanimation fadeInBottom">
                        <div className="other-section-title">
                            <h4>Top Selling</h4>
                        </div>
                        {ProductData?.slice(0, 3).map((row) => (
                            <ItemFour data={row} key={row.id} />
                        ))}
                    </div>
                    <div className="other-section-item cssanimation fadeInBottom">
                        <div className="other-section-title">
                            <h4>Trending Products</h4>
                        </div>
                        {ProductData?.slice(5, 8).map((row) => (
                            <ItemFour data={row} key={row.id} />
                        ))}
                    </div>
                    <div className="other-section-item cssanimation fadeInBottom">
                        <div className="other-section-title">
                            <h4>Recently Added</h4>
                        </div>
                        {ProductData?.slice(9, 12).map((row) => (
                            <ItemFour data={row} key={row.id} />
                        ))}
                    </div>
                    <div className="other-section-item cssanimation fadeInBottom">
                        <div className="other-section-title">
                            <h4>Top Reated</h4>
                        </div>
                        {ProductData?.slice(13, 16).map((row) => (
                            <ItemFour data={row} key={row.id} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
