/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { faBars, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Cart from '../../../../assets/icons/Cart-not-fill.svg';
import SearchIcon from '../../../../assets/icons/search-icon.svg';
import User from '../../../../assets/icons/User.svg';
import Wishlist from '../../../../assets/icons/Wishlist.svg';
import Logo from '../../../../assets/images/Logo.svg';
import CategoryInfo from '../../../components/section/CategoryInfo';
import HeaderSideBarMenu from '../../../components/section/HeaderSideBarMenu';
import UserInfo from '../../../components/section/UserInfo';

function classNameControl(defClassName, top) {
    const newClassList = [defClassName];

    if (top > 140) {
        newClassList.push();

        newClassList.push('position-higher');
    } else {
        newClassList.push('position-lower');
    }

    return newClassList;
}
const HeaderTop = ({ IsLogin }) => (
    <div className="header-section-top d-none d-lg-block d-xl-block">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-4 col-xl-4 col-md-4">
                    <div className="header-section-top-menu">
                        <ul>
                            <li>
                                <Link to="/">Help ? 88021235</Link>
                            </li>
                            <li>
                                <Link to="/">USD</Link>
                            </li>
                            <li>
                                <Link to="/">English</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-12 col-lg-4 col-xl-4 col-md-4" />
                <div className="col-12 col-lg-4 col-xl-4 col-md-4">
                    <div className="header-section-top-menu">
                        <ul className="float-right">
                            <li>
                                <Link to="/about-us/">About Us</Link>
                            </li>
                            {IsLogin ? (
                                <>
                                    <li>
                                        <Link to="/user/dashboard/">My Account</Link>
                                    </li>
                                    <li>
                                        <Link to="/user/wishlist/">Wishlist</Link>
                                    </li>
                                </>
                            ) : null}
                            <li>
                                <Link to="/order-track/">Order Tracking</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
const HeaderMiddle = ({ setShowMenu, IsLogin }) => {
    const [show, setShow] = useState(false);
    const handleHide = () => {
        setShow(false);
    };

    return (
        <div className="header-section-middle">
            <div className="container" style={{ position: 'relative' }}>
                <div className="row">
                    <div className="col-2 d-lg-none d-xl-none d-md-none">
                        {/* <i class="fa-solid fa-bars-staggered"></i> */}
                        <FontAwesomeIcon
                            size="lg"
                            icon={faBars}
                            onClick={() => setShowMenu(true)}
                        />
                    </div>
                    <div className="col-5 col-lg-3 col-xl-3 col-md-3 position-relative">
                        <Link to="/" className="header-section-logo">
                            <img src={Logo} alt="" />
                        </Link>
                    </div>
                    <div className="col-6 d-none d-lg-block d-xl-block d-md-block">
                        <div className="header-section-middle-cat">
                            <div className="header-section-middle-input-group">
                                <select className="form-control">
                                    <option>Fish</option>
                                    <option>Meat</option>
                                    <option>Vegitable</option>
                                </select>
                                <FontAwesomeIcon
                                    size="lg"
                                    color="#FFFFF"
                                    icon={show ? faChevronUp : faChevronDown}
                                />
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Type Items Name"
                                />
                                <button type="button" className="header-section-search-btn">
                                    <img
                                        src={SearchIcon}
                                        alt="Search"
                                        style={{ marginRight: 15 }}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-5 col-lg-3 col-xl-3 col-md-4 ">
                        <div className="header-section-info">
                            <ul>
                                {IsLogin ? (
                                    <li>
                                        <Link to="/user/wishlist/">
                                            <img src={Wishlist} alt="" />
                                            <span>Wishlist</span>
                                        </Link>
                                    </li>
                                ) : null}
                                <li>
                                    <Link to="/cart/">
                                        <img src={Cart} alt="" />
                                        <span>Cart</span>
                                    </Link>
                                </li>
                                {IsLogin ? (
                                    <li>
                                        <Link onClick={() => setShow(true)}>
                                            <img src={User} alt="" />
                                            <span> Mr.Alex</span>
                                        </Link>
                                    </li>
                                ) : (
                                    <li>
                                        <Link to="/login/">
                                            <img src={User} alt="" />
                                            <span> Account</span>
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                <div
                    tabIndex={0}
                    role="button"
                    className={['top-box-foreground', show ? 'show' : ''].join(' ')}
                    onClick={handleHide}
                />
                <div className={['top-box', show ? 'show' : ''].join(' ')} onClick={handleHide}>
                    <UserInfo userInformation="" />
                </div>
            </div>
        </div>
    );
};
const HeaderBottom = () => {
    const [show, setShow] = useState(false);
    const headerRef2 = useRef();
    const headerRef = useRef();

    const handleHide = () => {
        setShow(false);
    };
    useEffect(() => {
        if (headerRef?.current) {
            headerRef.current.className = classNameControl(
                'header-section-bottom d-none d-md-block d-lg-block d-xl-block',
                0
            ).join(' ');

            headerRef2.current.className = classNameControl('fake-height', 0).join(' ');

            const onScroll = (e) => {
                const topPosition = e?.target?.documentElement?.scrollTop || '';
                headerRef.current.className = classNameControl(
                    'header-section-bottom d-none d-md-block d-lg-block d-xl-block',
                    topPosition
                ).join(' ');

                headerRef2.current.className = classNameControl('fake-height', topPosition).join(
                    ' '
                );
            };
            window.addEventListener('scroll', onScroll);

            return () => window.removeEventListener('scroll', onScroll);
        }
        return null;
    }, []);

    return (
        <>
            <div ref={headerRef2} />
            <div className="header-section-bottom d-none d-lg-block d-xl-block" ref={headerRef}>
                <div className="container">
                    <div className="row">
                        <div className="col-3">
                            <div
                                className="header-section-bottom-cat"
                                onClick={() => setShow(true)}
                            >
                                <button type="button">Browser Category</button>
                                <FontAwesomeIcon
                                    size="lg"
                                    color="white"
                                    icon={show ? faChevronUp : faChevronDown}
                                />
                            </div>
                        </div>
                        <div className="col-7">
                            <div className="header-section-bottom-menu">
                                <ul>
                                    <li className="has-sub-menu">
                                        <Link to="/">
                                            Home{' '}
                                            <FontAwesomeIcon
                                                size="xs"
                                                color="black"
                                                icon={faChevronDown}
                                            />
                                        </Link>
                                        <ul className="header-section-sub-menu">
                                            <li>
                                                <Link to="/">Home-1</Link>
                                            </li>
                                            <li>
                                                <Link to="/home-2">Home-2</Link>
                                            </li>
                                            <li>
                                                <Link to="/home-3">Home-3</Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        <Link to="/about-us/">About</Link>
                                    </li>

                                    <li className="has-sub-menu">
                                        <Link to="/">
                                            Shop{' '}
                                            <FontAwesomeIcon
                                                size="xs"
                                                color="black"
                                                icon={faChevronDown}
                                            />
                                        </Link>
                                        <ul className="header-section-sub-menu">
                                            <li>
                                                <Link to="/category">Shop</Link>
                                            </li>
                                            <li>
                                                <Link to="/category/list/left-side">Shop-2</Link>
                                            </li>
                                            <li>
                                                <Link to="/category/list/right-side">Shop-3</Link>
                                            </li>
                                            <li>
                                                <Link to="/category/left-side">Shop-4</Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        <Link to="/vendor/">Vendor</Link>
                                    </li>

                                    <li>
                                        <Link to="/blog/">Blog</Link>
                                    </li>

                                    <li>
                                        <Link to="/contact/">Contact</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="header-section-bottom-help">
                                Help ? 16222
                                <small>24/7 Support Center</small>
                            </div>
                        </div>
                    </div>
                    <div
                        tabIndex={0}
                        role="button"
                        className={['top-box-foreground', show ? 'show' : ''].join(' ')}
                        onClick={handleHide}
                    />
                    <div
                        className={['category-box', show ? 'show' : ''].join(' ')}
                        onClick={handleHide}
                    >
                        <CategoryInfo userInformation="" />
                    </div>
                </div>
            </div>
        </>
    );
};
export default function HeaderSection() {
    const [show, setShowMenu] = useState(false);
    const IsLogin = localStorage.getItem('User-Access');

    return (
        <>
            <div className="header-section">
                <HeaderTop IsLogin={IsLogin} />
                <HeaderMiddle setShowMenu={setShowMenu} IsLogin={IsLogin} />
                <HeaderBottom />
            </div>
            {show ? <HeaderSideBarMenu show={show} setShow={setShowMenu} /> : null}
        </>
    );
}
