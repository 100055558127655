import ImgBanner1 from '../images/banner-1.png';
import ImgBanner2 from '../images/banner-2.png';
import ImgBanner3 from '../images/banner-3.png';
import Img1 from '../images/cat-1.png';
import Img7 from '../images/cat-12.png';
import Img3 from '../images/cat-3.png';
import Img4 from '../images/cat-4.png';
import Img5 from '../images/cat-5.png';
import Img6 from '../images/cat-9.png';

export const BannerData = [
    {
        id: 10001,
        title: 'Everyday Fresh & Clean Our Products',
        price: '100',
        detail: 'Cake & Milk',
        bg: '#FFF3FF',
        img: ImgBanner1,
    },
    {
        id: 10002,
        title: 'Make your Breakfast Healthy and Easy',
        price: '100',
        detail: 'Oganic Kiwi',
        bg: '#FFF3EB',
        img: ImgBanner2,
    },
    {
        id: 10003,
        title: 'The best Organic Products Online',
        price: '100',
        detail: 'Peach',
        bg: '#ECFFEC',
        img: ImgBanner3,
    },
];
const FeatureData = [
    {
        id: 1001,
        title: 'Cake & Milk',
        price: '100',
        detail: 'Cake & Milk',
        bg: '#FFF3FF',
        img: Img1,
    },
    {
        id: 1002,
        title: 'Oganic Kiwi',
        price: '100',
        detail: 'Oganic Kiwi',
        bg: '#FFF3EB',
        img: Img4,
    },
    {
        id: 1003,
        title: 'Peach',
        price: '100',
        detail: 'Peach',
        bg: '#ECFFEC',
        img: Img3,
    },
    {
        id: 1004,
        title: 'Red Apple',
        price: '100',
        detail: 'Red Apple',
        bg: '#FFFCEB',
        img: Img4,
    },
    {
        id: 1005,
        title: 'Snack',
        price: '100',
        detail: 'Snack',
        bg: '#FFF3EB',
        img: Img5,
    },
    {
        id: 1006,
        title: 'Vegetables',
        price: '100',
        detail: 'Vegetables',
        bg: '#ECFFEC',
        img: Img6,
    },
    {
        id: 1007,
        title: 'Strawberry',
        price: '100',
        detail: 'Strawberry',
        bg: '#FFF3EB',
        img: Img7,
    },
    {
        id: 1008,
        title: 'Black plum',
        price: '100',
        detail: 'Black plum',
        bg: '#ECFFEC',
        img: Img6,
    },

    {
        id: 1009,
        title: 'Custard apple',
        price: '100',
        detail: 'Custard apple',
        bg: '#FFF3FF',
        img: Img3,
    },
    {
        id: 1010,
        title: 'Coffe & Tea',
        price: '100',
        detail: 'Coffe & Tea',
        bg: '#FFF3EB',
        img: Img1,
    },
];

export default FeatureData;
