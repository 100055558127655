import React from 'react';
import Breadcrumb from '../../../components/section/Breadcrumb';
import AboutSection from '../section/AboutSection';
import FooterSection from '../section/FooterSection';
import HeaderSection from '../section/HeaderSection';
import SubscribeSection from '../section/SubscribeSection';

function AboutPage() {
    return (
        <>
            <HeaderSection />
            <Breadcrumb title="About Us" />
            <AboutSection />
            <SubscribeSection />
            <FooterSection />
        </>
    );
}

export default AboutPage;
