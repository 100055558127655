import React from 'react';
import { Link } from 'react-router-dom';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FeatureData, { BannerData } from '../../../../assets/data/FeatureData';

export default function FeaturedCategoriesSection() {
    return (
        <div className="container">
            <div className="featured-categories-section">
                <div className="title">
                    <h4>Featured Categories</h4>
                </div>
                <div className="featured-categories-section-list mb-4">
                    {FeatureData?.map((row) => (
                        <Link
                            to="/category"
                            className="item-info animate fadeInLeft"
                            key={row.id}
                            style={{
                                backgroundColor: row.bg,
                                animationDdelay: `${parseFloat(0.5)}s`,
                                webkitAnimationDelay: `${parseFloat(0.5)}s`,
                                mozAnimationDelay: `${parseFloat(0.5)}s`,
                            }}
                        >
                            <img src={row.img} alt="" />
                            <p>{row.title}</p>
                            <small>12 items</small>
                        </Link>
                    ))}
                </div>
                <div className="featured-categories-section-list">
                    {BannerData?.map((row) => (
                        <div className="item-banner-info" key={row.id}>
                            <div className="item-banner-info-text">
                                <h4> {row?.title}</h4>
                                <Link to="/">
                                    Shop Now{' '}
                                    <FontAwesomeIcon
                                        size="sm"
                                        icon={faArrowRight}
                                        style={{ marginLeft: 5 }}
                                    />
                                </Link>
                            </div>

                            <img src={row.img} alt="" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
