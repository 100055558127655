const AddressData = [
    {
        id: 100001,
        title: 'Mr.React Js',
        phone: '01746486140',
        address: {
            flat: '49',
            road: '36',
            address: 'jalma',
            city: 'Khulna',
            district: 'khulna',
        },
    },
    {
        id: 100002,
        title: 'Mr.Javascript',
        phone: '01934335477',
        address: {
            flat: '49',
            road: '36',
            address: 'jalma',
            city: 'Khulna',
            district: 'khulna',
        },
    },
    {
        id: 100003,
        title: 'Mr.Pitter',
        phone: '01746486141',
        address: {
            flat: '49',
            road: '36',
            address: 'jalma',
            city: 'Khulna',
            district: 'khulna',
        },
    },
    {
        id: 100004,
        title: 'Mr.Alex',
        phone: '01746486142',
        address: {
            flat: '49',
            road: '36',
            address: 'jalma',
            city: 'Khulna',
            district: 'khulna',
        },
    },
];
export default AddressData;
