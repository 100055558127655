import { faBell, faCartArrowDown, faHome, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import fbFoot from '../../../../assets/icons/fb-foot.svg';
import inFoot from '../../../../assets/icons/in-foot.svg';
import twFoot from '../../../../assets/icons/tw-foot.svg';
import ytFoot from '../../../../assets/icons/yt-foot.svg';
import Logo from '../../../../assets/images/Logo.svg';
import storeIcon from '../../../../assets/images/store-icon.png';

export default function FooterSection() {
    return (
        <>
            <div className="footer-section">
                <div className="footer-section-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-4 col-xl-4 col-md-6">
                                <div className="footer-section-intro">
                                    <a href="/">
                                        <img src={Logo} className="footer-logo" alt="Logo" />
                                    </a>
                                    <p>
                                        Far far away, behind the word mountains, far from the
                                        countries Vokalia and Consonantia, there live the blind
                                        texts. Separated they live in Bookmarksgrove right at the
                                        coast of the Semantics, a large language ocean
                                    </p>
                                </div>
                            </div>
                            <div className="col-6 col-lg-2 col-xl-2 col-md-3">
                                <div className="footer-section-menu">
                                    <h3>Company</h3>
                                    <div className="footer-section-menu-devider" />
                                    <ul>
                                        <li>
                                            <a href="/">About Us</a>
                                        </li>
                                        <li>
                                            <a href="/">Contact Us</a>
                                        </li>
                                        <li>
                                            <a href="/">Delivery Info</a>
                                        </li>
                                        <li>
                                            <a href="/">Privacy Policies</a>
                                        </li>
                                        <li>
                                            <a href="/">Support Center</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-6 col-lg-2 col-xl-2 col-md-3">
                                <div className="footer-section-menu">
                                    <h3>Account</h3>
                                    <div className="footer-section-menu-devider" />
                                    <ul>
                                        <li>
                                            <a href="/login">Sign In</a>
                                        </li>
                                        <li>
                                            <a href="/">View Cart</a>
                                        </li>
                                        <li>
                                            <a href="/">My Wishlist</a>
                                        </li>
                                        <li>
                                            <a href="/">Truck My Order</a>
                                        </li>
                                        <li>
                                            <a href="/">Ticket</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-6 col-lg-2 col-xl-2 col-md-4">
                                <div className="footer-section-menu">
                                    <h3>Popular</h3>
                                    <div className="footer-section-menu-devider" />
                                    <ul>
                                        <li>
                                            <a href="/">Become a Vendor</a>
                                        </li>
                                        <li>
                                            <a href="/">Affiliate Program</a>
                                        </li>
                                        <li>
                                            <a href="/">Suppiliers</a>
                                        </li>
                                        <li>
                                            <a href="/">Promotions</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-6 col-lg-2 col-xl-2 col-md-4">
                                <div className="footer-section-menu">
                                    <h3>App</h3>
                                    <div className="footer-section-menu-devider" />
                                    <a href="/">
                                        <img src={storeIcon} alt="Logo" style={{ width: '100%' }} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-section-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-6-lg col-xl-6 col-md-6">
                                <div className="footer-section-copy-right">
                                    <p> © mMart All Right Reserved</p>
                                </div>
                            </div>
                            <div className="col-12 col-6-lg col-xl-6 col-md-6">
                                <div className="footer-section-social">
                                    <ul>
                                        <li className="">
                                            <a href="/">
                                                <img src={fbFoot} alt="" />
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href="/">
                                                <img src={twFoot} alt="" />
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href="/">
                                                <img src={inFoot} alt="" />
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href="/">
                                                <img src={ytFoot} alt="" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="footer-section-auto-height" />
            <section className="footer-section-mobile">
                <div className="footer-mobile">
                    <div className="container">
                        <div className="footer-mobile-menu">
                            <ul>
                                <li>
                                    <Link to="/">
                                        <FontAwesomeIcon size="lg" icon={faHome} />
                                        <div>Home</div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to=".">
                                        <FontAwesomeIcon size="lg" icon={faBell} />
                                        <div> Notification</div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/cart/">
                                        <FontAwesomeIcon size="lg" icon={faCartArrowDown} />
                                        <div> Cart</div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/user/dashboard/">
                                        <FontAwesomeIcon size="lg" icon={faUser} />
                                        <div> Account</div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
