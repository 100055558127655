import React from 'react';
import SendIcon from '../../../../assets/icons/Send-icon.svg';
import SubsBanner from '../../../../assets/images/banner-9.png';

export default function SubscribeSection() {
    return (
        <div className="container">
            <div className="subscribe-section">
                <div className="subscribe-section-item">
                    <h2>Stay home & get your daily needs from our shop</h2>
                    <p>Start Your Daily Shopping with Nest Mart</p>
                    <div className="subscribe-input">
                        <div className="input-group mb-3">
                            <span className="input-group-icon">
                                <img src={SendIcon} alt="" />
                            </span>
                            <input type="text" aria-label="" placeholder="Your Email Address" />
                            <span className="input-group-subscribe">Subscribe</span>
                        </div>
                    </div>
                </div>
                <div className="subscribe-section-item-img">
                    <img src={SubsBanner} alt="" />
                </div>
            </div>
        </div>
    );
}
