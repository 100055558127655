import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ProductData from '../../../../assets/data/ProductData';
import QtyBtn from '../../../components/section/QtyBtn';

export default function CartSection() {
    const navigate = useNavigate();
    const handleProceed = () => {
        navigate('/checkout');
    };
    return (
        <div className="container mb-5">
            <div className="row">
                <div className="col-12">
                    <h3>Your Cart</h3>
                    <p>There are 3 products in your cart</p>
                </div>
                <div className="col-12 col-lg-8 col-xl-8 col-md-6">
                    <div className="cart-detail-list">
                        <table>
                            <thead>
                                <th>
                                    <input type="checkbox" />
                                </th>
                                <th>Products</th>
                                <th>Unit Price</th>
                                <th>Quantity</th>
                                <th>SubTotal</th>
                                <th>Action</th>
                            </thead>
                            <tbody>
                                {ProductData?.slice(0, 10).map((row) => (
                                    <tr key={row?.id}>
                                        <td>
                                            {' '}
                                            <input type="checkbox" />
                                        </td>
                                        <td>
                                            <img src={row?.img['0']} alt={row?.title} />{' '}
                                            {row?.title}
                                        </td>
                                        <td>{row?.price}</td>
                                        <td>
                                            <div className="product-qty-btn">
                                                <QtyBtn />
                                            </div>
                                        </td>
                                        <td>{row?.price}</td>
                                        <td>
                                            <FontAwesomeIcon size="sm" color="red" icon={faTrash} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <button type="button">
                            {' '}
                            <FontAwesomeIcon size="lg" icon={faTrash} /> Empty Cart
                        </button>
                        <button type="button" className="float-right">
                            Update
                        </button>
                    </div>
                </div>
                <div className="col-12 col-lg-4 col-xl-4 col-md-6">
                    <div className="cart-detail-summery">
                        <div className="cart-detail-summery-title">
                            <h3>Cart Summary</h3>
                        </div>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Subtotal (1 items)</td>
                                    <td className="text-right">৳ 196.00</td>
                                </tr>
                                <tr>
                                    <td>Total</td>
                                    <td className="text-right">৳ 196.00</td>
                                </tr>
                            </tbody>
                        </table>
                        <button type="button" onClick={handleProceed}>
                            Proceed to Checkout
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
