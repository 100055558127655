import React from 'react';
import ProductData from '../../../../assets/data/ProductData';
import Item from '../../../components/section/Item';
import ItemThree from '../../../components/section/ItemThree';
import ProductDetailSidebar from './ProductDetailSidebar';

function VendorDetailSection() {
    return (
        <div className="container">
            <div className="row mb-1">
                <div className="col-12 col-lg-9 col-xl-9 col-md-9">
                    <div className="row g-4">
                        <div className="col-12">
                            <div className="category-section-title">
                                <p>We found 29 items for you!</p>
                                <select>
                                    <option>Show :10</option>
                                    <option>50</option>
                                    <option>100</option>
                                    <option>150</option>
                                </select>
                                <select>
                                    <option>Sort By :Feature</option>
                                    <option>50</option>
                                    <option>100</option>
                                    <option>150</option>
                                </select>
                            </div>
                        </div>
                        {ProductData?.slice(0, 10).map((row) => (
                            <div
                                className="col-12 col-lg-3 col-xl-3 col-md-6"
                                key={`all-${row?.id}`}
                            >
                                <Item data={row} />
                            </div>
                        ))}
                        <div className="col-12">
                            <div className="deals-day-section">
                                <div className="deals-day-section-title">
                                    <h4>Deals Of The Day</h4>
                                </div>
                                <div className="deals-day-section-list">
                                    {ProductData?.slice(0, 4).map((row) => (
                                        <div
                                            className="deals-day-section-item-vendor"
                                            key={`deal-d-${row?.id}`}
                                        >
                                            <ItemThree data={row} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ProductDetailSidebar isVendor />
            </div>
        </div>
    );
}

export default VendorDetailSection;
