import React from 'react';
import WelcomeModal from '../../../components/section/WelcomeModal';
import DailyBestSellSection from '../section/DailyBestSellSection';
import DealsDaySection from '../section/DealsDaySection';
import FeaturedCategoriesSectionTwo from '../section/FeaturedCategoriesSectionTwo';
import FooterSection from '../section/FooterSection';
import HeaderSection from '../section/HeaderSection';
import OptionSection from '../section/OptionSection';
import OtherSection from '../section/OtherSection';
import PopularProductSection from '../section/PopularProductSection';
import SliderSectionTwo from '../section/SliderSectionTwo';
import SubscribeSection from '../section/SubscribeSection';

export default function IndexPageTwo() {
    return (
        <>
            <HeaderSection />
            <SliderSectionTwo />
            <PopularProductSection />
            <FeaturedCategoriesSectionTwo />
            <DealsDaySection />
            <DailyBestSellSection />
            <OtherSection />
            <SubscribeSection />
            <OptionSection />
            <FooterSection />
            <WelcomeModal />
        </>
    );
}
