import React from 'react';
import VendorData from '../../../../assets/data/VendorData';
import VendorListGrid from '../../../components/section/VendorListGrid';

function VendorSection() {
    return (
        <div className="container mb-5">
            <div className="row g-4">
                {VendorData?.map((row) => (
                    <div className="col-12 col-lg-4 col-xl-3 col-md-6" key={row.id}>
                        <VendorListGrid data={row} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default VendorSection;
