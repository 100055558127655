import React from 'react';
import Breadcrumb from '../../../components/section/Breadcrumb';
import CategorySectionListRightSide from '../section/CategorySectionListRightSide';
import FooterSection from '../section/FooterSection';
import HeaderSection from '../section/HeaderSection';
import OptionSection from '../section/OptionSection';
import SubscribeSection from '../section/SubscribeSection';

export default function CategoryPageListRightBar() {
    return (
        <>
            <HeaderSection />
            <Breadcrumb title="Category" />
            <CategorySectionListRightSide />
            <SubscribeSection />
            <OptionSection />
            <FooterSection />
        </>
    );
}
