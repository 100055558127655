import React from 'react';
import ImageGallery from 'react-image-gallery';

export default function ImageSliderComponent({ thumbImages }) {
    const images = [];

    thumbImages.map((imgUrl) =>
        images.push({
            original: `${imgUrl.id}`,
            thumbnail: `${imgUrl.id}`,
        })
    );
    return (
        <div className="product-detail-imgBox">
            <ImageGallery slideDuration={1000} items={images} />
        </div>
    );
}
