/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { faFacebookF, faGooglePlusG } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function LoginArea({ setType }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleUserName = (e) => {
        setUsername(e.target.value);
    };
    const handlePassword = (e) => {
        setPassword(e.target.value);
    };
    const handleLogin = () => {
        localStorage.setItem('User-Access', JSON.stringify({ userName: username, password }));
        navigate('/user/dashboard/');
    };
    return (
        <div className="login-section">
            <h4>
                Login{' '}
                <span>
                    New member?{' '}
                    <a href="#" onClick={() => setType('sign')}>
                        Register
                    </a>{' '}
                    here.
                </span>
            </h4>
            <div className="row p-xl-5 p-lg-4 p-2">
                <div className="col-12 col-lg-6 col-xl-6 col-md-6">
                    <label>Phone Number or Email</label>
                    <input
                        type="text"
                        placeholder="Please enter your Phone Number or EmailType Name"
                        value={username}
                        onChange={handleUserName}
                    />
                    <label>Password</label>
                    <input
                        type="password"
                        placeholder="*******"
                        value={password}
                        onChange={handlePassword}
                    />
                    <Link to="/" className="float-right">
                        Forgot Password?
                    </Link>
                </div>
                <div className="col-12 col-lg-6 col-xl-6 col-md-6">
                    <button type="button" className="login-btn" onClick={handleLogin}>
                        Login
                    </button>
                    <small>Or Login With</small>
                    <button type="button" className="fb-btn">
                        <FontAwesomeIcon size="lg" icon={faFacebookF} /> Facebook
                    </button>
                    <button type="button" className="google-btn">
                        <FontAwesomeIcon size="lg" icon={faGooglePlusG} />
                        Google
                    </button>
                </div>
            </div>
        </div>
    );
}

function SignUpArea() {
    return (
        <div className="login-section">
            <h4>Sign Up</h4>
            <div className="row p-xl-5 p-lg-4 p-2">
                <div className="col-12 col-lg-6 col-xl-6 col-md-6">
                    <label>Full Name</label>
                    <input type="text" placeholder="Please enter your Name" value="" />
                    <label>Phone Number </label>
                    <input type="text" placeholder="Please enter your Phone Number" value="" />
                    <label>Password</label>
                    <input type="password" placeholder="*******" value="" />
                </div>
                <div className="col-12 col-lg-6 col-xl-6 col-md-6">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                        />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            I'd like to receive exclusive offers and promotions via SMS{' '}
                        </label>
                    </div>

                    <button type="button" className="login-btn">
                        Sign Up
                    </button>
                    <small />
                    <button type="button" className="fb-btn">
                        <FontAwesomeIcon size="lg" icon={faFacebookF} /> Facebook
                    </button>
                    <button type="button" className="google-btn">
                        <FontAwesomeIcon size="lg" icon={faGooglePlusG} />
                        Google
                    </button>
                </div>
            </div>
        </div>
    );
}
function LoginSection() {
    const [type, setType] = useState('login');

    return (
        <div className="container">
            {type === 'login' ? <LoginArea setType={setType} /> : <SignUpArea />}
        </div>
    );
}

export default LoginSection;
