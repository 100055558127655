import React from 'react';
import { Link } from 'react-router-dom';
import CartIcon from '../../../assets/icons/Cart-white.svg';
import StarFill from '../../../assets/icons/star-fill.svg';
import StarNotFill from '../../../assets/icons/star-not-fill.svg';

export default function ItemList({ data }) {
    const fillStar = <img src={StarFill} style={{ width: 10, marginRight: 3 }} alt="" />;
    const fillNotStar = <img src={StarNotFill} style={{ width: 10, marginRight: 3 }} alt="" />;

    return (
        <div className="item-list">
            <div className="item-list-img">
                <img src={data?.img['0']} className="item-img-1" alt={data?.title} />
                <img src={data?.img['1']} className="item-img-2" alt={data?.title} />
            </div>
            <Link to="/detail" className="item-list-info">
                <small>{data?.category}</small>
                <h5>{data?.title}</h5>
                <span>
                    {fillStar}
                    {fillStar}
                    {fillStar}
                    {fillStar}
                    {fillNotStar}(4.0)
                </span>
                <p>Vendor</p>
                <div className="price">
                    <p>${data?.price}</p>
                    <Link to=".">
                        <img src={CartIcon} alt="Cart" />
                        Add
                    </Link>
                </div>
            </Link>
        </div>
    );
}
