import React from 'react';
import FooterSection from '../section/FooterSection';
import HeaderSection from '../section/HeaderSection';
import LoginSection from '../section/LoginSection';

export default function LoginPage() {
    return (
        <>
            <HeaderSection />
            <LoginSection />
            <FooterSection />
        </>
    );
}
