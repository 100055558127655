/* eslint-disable jsx-a11y/iframe-has-title */
import { faBuilding, faEnvelope, faFax, faMobile, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function ContactSection() {
    return (
        <div className="container">
            <div className="contact-section">
                <div className="row">
                    <div className="col-12 col-lg-6 col-xl-6">
                        <div className="contact-section-info">
                            <h4>Contact Info</h4>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry`s standard dummy text
                                ever since the 1500s, when an unknown printer took a galley of type
                                and scrambled it to make a type specimen book. It has survived not
                                only five centuries, but also the leap into electronic
                            </p>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <FontAwesomeIcon size="sm" icon={faBuilding} />
                                        </td>
                                        <td />
                                        <td />
                                        <td>8067 Spruce Street Owatonna, MN 55060</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <FontAwesomeIcon size="sm" icon={faMobile} />
                                        </td>
                                        <td />
                                        <td />
                                        <td> +123456789</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <FontAwesomeIcon size="sm" icon={faFax} />
                                        </td>
                                        <td />
                                        <td />
                                        <td>+0123456789</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <FontAwesomeIcon size="sm" icon={faEnvelope} />
                                        </td>
                                        <td />
                                        <td />
                                        <td> info@mMart.com</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 col-xl-6 mb-5">
                        <div className="contact-section-message">
                            <form className="row" id="form">
                                <div className="col-12 col-lg-6 col-xl-6">
                                    <div className="contact-section-message-input-group">
                                        <FontAwesomeIcon size="sm" icon={faUser} />
                                        <input
                                            type="text"
                                            placeholder="Type Your name"
                                            name="name"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 col-xl-6">
                                    <div className="contact-section-message-input-group">
                                        <FontAwesomeIcon size="sm" icon={faEnvelope} />
                                        <input
                                            type="email"
                                            placeholder="Type Your email"
                                            name="email_address"
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="contact-section-message-textarea">
                                        <textarea
                                            placeholder="Write your message here"
                                            name="message"
                                        />
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="contact-section-message-button">
                                        <button type="submit">Send message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-12">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d20336820.71391205!2d-91.00443897107375!3d41.61442283158211!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1668852333971!5m2!1sen!2sbd"
                            width="100%"
                            height="450"
                            allowfullscreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactSection;
