import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Countdown from 'react-countdown';
import StarFill from '../../../assets/icons/star-fill.svg';
import StarNotFill from '../../../assets/icons/star-not-fill.svg';

const renderer = ({ days, hours, minutes, seconds }) => (
    // Render a countdown
    <ul>
        <li>
            {days}
            <br />
            Days
        </li>
        <li>
            {hours}
            <br />
            Hours
        </li>
        <li>
            {minutes}
            <br />
            Mins
        </li>
        <li>
            {seconds}
            <br />
            Sec
        </li>
    </ul>
);
function WelcomeModalMain({ show, handleHideModal }) {
    if (localStorage.getItem('welcome') === 'true') {
        return null;
    }
    const fillStar = <img src={StarFill} style={{ width: 15, marginRight: 3 }} alt="" />;
    const fillNotStar = <img src={StarNotFill} style={{ width: 15, marginRight: 3 }} alt="" />;
    return (
        <Modal
            size="lg"
            className=""
            show={show}
            onHide={handleHideModal}
            aria-labelledby="example-modal-sizes-title-lg"
            centered
            backdrop
        >
            <Modal.Header className="welcome-modal-header" />
            <Modal.Body closeButton>
                <div className="welcome-modal">
                    <h6>Deal of the Day</h6>
                    <h2>Organic fruit for your familys health</h2>
                    <h1>$29.32</h1>
                    <small>Hurry Up! Offer End In:</small>
                    <div className="welcome-modal-time">
                        <Countdown date={Date.now() + 500000000} renderer={renderer} />
                    </div>
                    <div className="rating">
                        {fillStar}
                        {fillStar}
                        {fillStar}
                        {fillStar}
                        {fillNotStar}(4)
                    </div>
                    <button type="button">Shop Now</button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

function WelcomeModal() {
    const [showModal, setShowModal] = useState(true);
    const [initialTime, setInitialTime] = useState(10000);
    const [startTimer, setStartTimer] = useState(true);
    const welcome = localStorage.getItem('welcome');
    const handleHideModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        if (initialTime > 0) {
            setTimeout(() => {
                setInitialTime(initialTime - 1);
            }, 1000);
        }

        if (initialTime === 0 && startTimer) {
            setStartTimer(false);
            setShowModal(false);
        }
    }, [initialTime, startTimer]);

    if (!showModal) {
        return null;
    }
    return (
        <WelcomeModalMain
            show={showModal && welcome !== 'true'}
            handleHideModal={handleHideModal}
        />
    );
}

export default WelcomeModal;
